import { i18n } from '@/common/i18n'
import { listHubApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'

import {
  ExportAcademicSyncApiParams,
  ListAcademicSyncApiParams,
  ListAcademicSyncApiResult,
  ListExportAcademicSyncApiResult,
  ShowAcademicSyncApiResult
} from './contracts'
import {
  ExportAcademicSyncServiceParams,
  ImportAcademicSyncServiceParams,
  ListAcademicSyncServiceParams,
  ListAcademicSyncServiceResult,
  ListExportAcademicSyncServiceParams,
  ListExportAcademicSyncServiceResult,
  ShowAcademicSyncServiceParams,
  ShowAcademicSyncServiceResult
} from './contracts/services'
import {
  academicSyncFormatSanitizer,
  listAcademicSyncSanitizer,
  showAcademicSyncFormatSanitizer
} from './sanitizers'

export async function listAcademicSyncService({
  institutionId,
  page,
  perPage,
  termId
}: ListAcademicSyncServiceParams): Promise<ListAcademicSyncServiceResult> {
  const {
    data: { data, ...result }
  } = await hubApiService<ListAcademicSyncApiParams, ListAcademicSyncApiResult>({
    resource: i18n().modules.hub.academicSync.resources.listAcademicSync,
    method: 'get',
    url: `/academic-sync/v1/processor/sheet/institution/${institutionId}/import`,
    params: {
      limit: perPage,
      offset: page,
      term_id: termId
    }
  })

  return {
    ...listHubApiResultSanitizer({
      ...result,
      total_pages: result.pages,
      data: academicSyncFormatSanitizer(data)
    })
  }
}

export async function showAcademicSyncService({
  institutionId,
  importId
}: ShowAcademicSyncServiceParams): Promise<ShowAcademicSyncServiceResult> {
  const { data } = await hubApiService<null, ShowAcademicSyncApiResult>({
    resource: i18n().modules.hub.academicSync.resources.showAcademicSync,
    method: 'get',
    url: `/academic-sync/v1/processor/sheet/institution/${institutionId}/import/${importId}`
  })

  return showAcademicSyncFormatSanitizer(data)
}

export async function importAcademicSyncService({
  file,
  institutionId
}: ImportAcademicSyncServiceParams): Promise<void> {
  await hubApiService<FormData, ShowAcademicSyncApiResult>({
    resource: i18n().modules.hub.academicSync.resources.listAcademicSync,
    method: 'post',
    url: `/academic-sync/v1/processor/sheet/institution/${institutionId}/import`,
    body: file
  })
}

export async function exportAcademicSyncService({
  filter,
  schoolYearCode,
  orgId
}: ExportAcademicSyncServiceParams): Promise<void> {
  await hubApiService<ExportAcademicSyncApiParams, null>({
    resource: i18n().modules.hub.academicSync.resources.exportAcademicSync,
    method: 'post',
    url: `/admin/v1/org/${orgId}/export-data`,
    body: {
      filter,
      school_year_code: schoolYearCode
    }
  })
}

export async function listExportAcademicSyncService({
  orgId
}: ListExportAcademicSyncServiceParams): Promise<ListExportAcademicSyncServiceResult[]> {
  const { data } = await hubApiService<null, { data: ListExportAcademicSyncApiResult[] }>({
    resource: i18n().modules.hub.academicSync.resources.listExportAcademicSync,
    method: 'get',
    url: `/admin/v1/org/${orgId}/export-data`
  })

  return listAcademicSyncSanitizer(data.data)
}
