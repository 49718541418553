import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'

import { ListBannerJsonHookParams, ListBannerJsonHookResult } from './contracts'
import { listBannerJsonService } from './service'

const hookKey = 'banner'

export function useListBannerJson({
  queryOptions,
  onSuccess,
  onError
}: ListBannerJsonHookParams): UseQueryResult<ListBannerJsonHookResult[], ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'list'],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedBannerJsonService = await listBannerJsonService()
        onSuccess?.(listedBannerJsonService)
        return listedBannerJsonService
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}
