import { Navigate, Route, Routes } from 'react-router-dom'

import { HomeApplications } from './applications/pages/Home'
import { DataAnalysisRoutes } from './data-analysis/routes'
import { DataIntelligenceRoutes } from './data-intelligence/routes'
import { DataManagementRoutes } from './data-management/routes'
import { EducationalContentsRoutes } from './educational-contents/routes'
import { MonitooraRoutes } from './monitoora/routes'
import { QuickAccessRoutes } from './quick-access/routes'
import { ReportRoutes } from './reports/routes'

export function HubRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/home" />} />
      <Route path="/home" element={<HomeApplications />} />
      <Route path="/data-analysis/*" element={<DataAnalysisRoutes />} />
      <Route path="/data-intelligence/*" element={<DataIntelligenceRoutes />} />
      <Route path="/monitoora/*" element={<MonitooraRoutes />} />
      <Route path="/data-management/*" element={<DataManagementRoutes />} />
      <Route path="/quick-access/*" element={<QuickAccessRoutes />} />
      <Route path="/reports/*" element={<ReportRoutes />} />
      <Route path="/*" element={<EducationalContentsRoutes />} />

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  )
}
