import { hubApiService } from '@/common/services'

import {
  ListAccessApiParams,
  ListAccessApiResult,
  ListAccessApplicationApiParams,
  ListAccessApplicationApiResult,
  ListAccessApplicationSchoolRolesApiParams,
  ListAccessApplicationSchoolRolesApiResult,
  ListAccessApplicationUniqueRolesApiParams,
  ListAccessApplicationUniqueRolesApiResult,
  ListAccessSummaryApiParams,
  ListAccessSummaryApiResult,
  ListAccessUniqueApiParams,
  ListAccessUniqueApiResult,
  ListApplicationApiResult,
  ListChildrenApiResult,
  ListSchoolAccessApiParams,
  ListSchoolAccessApiResult,
  ListUniqueApplicationApiParams,
  ListUniqueApplicationApiResult,
  ListUsersManagementApiParams,
  ListUsersManagementApiResult
} from './contracts/api'
import {
  ListAccessApplicationSchoolRolesServiceParams,
  ListAccessApplicationSchoolRolesServiceResult,
  ListAccessApplicationUniqueRolesServiceParams,
  ListAccessApplicationUniqueRolesServiceResult,
  ListAccessApplicationUniqueServiceParams,
  ListAccessApplicationUniqueServiceResult,
  ListAccessFormattedServiceResult,
  ListAccessServiceParams,
  ListAccessSummaryServiceParams,
  ListAccessSummaryServiceResult,
  ListAccessUniqueFormattedServiceResult,
  ListAccessUniqueServiceParams,
  ListApplicationServiceParams,
  ListApplicationServiceResult,
  ListChildrenServiceParams,
  ListChildrenServiceResult,
  ListSchoolAccessServiceParams,
  ListSchoolAccessServiceResult,
  ListUniqueApplicationFormattedServiceResult,
  ListUniqueApplicationServiceParams,
  ListUsersManagementFormattedServiceResult,
  ListUsersManagementServiceParams
} from './contracts/service'
import {
  ListSchoolAccessSanitizer,
  listAccessApplicationSchoolRolesSanitizer,
  listAccessApplicationUniqueRolesSanitizer,
  listAccessApplicationUniqueSanitizer,
  listAccessSummarySanitizer,
  listAccessUniqueSanitizer,
  listChildrenSanitizer,
  listUniqueApplicationSanitizer,
  listUsersManagementSanitizer
} from './sanitizers'

export async function listAccessSummaryService(
  params: ListAccessSummaryServiceParams
): Promise<ListAccessSummaryServiceResult> {
  const { data } = await hubApiService<ListAccessSummaryApiParams, ListAccessSummaryApiResult>({
    resource: '',
    method: 'get',
    url: `/access-summary`,
    baseURL: import.meta.env.VITE_DATAVIZ_API_URL_URL,
    params: {
      institutionId: params.institutionId,
      role: params.role,
      userId: params.userId
    }
  })

  return data
}

export async function listAccessService(
  params: ListAccessServiceParams
): Promise<ListAccessFormattedServiceResult> {
  const { data } = await hubApiService<ListAccessApiParams, ListAccessApiResult>({
    resource: '',
    method: 'get',
    url: `/access`,
    baseURL: import.meta.env.VITE_DATAVIZ_API_URL_URL,
    params: {
      institutionId: params.institutionId,
      role: params.role,
      applicationId: params.applicationId,
      roleFilter: params.roleFilter,
      userId: params.userId
    }
  })

  return listAccessSummarySanitizer(data)
}

export async function listApplicationService({
  institutionId
}: ListApplicationServiceParams): Promise<ListApplicationServiceResult[]> {
  const { data } = await hubApiService<null, ListApplicationApiResult[]>({
    resource: '',
    method: 'get',
    url: `/access/institutions/${institutionId}/apps`,
    baseURL: import.meta.env.VITE_DATAVIZ_API_URL_URL
  })

  return data
}

export async function listUsersManagementService(
  params: ListUsersManagementServiceParams
): Promise<ListUsersManagementFormattedServiceResult> {
  const { data } = await hubApiService<
    ListUsersManagementApiParams,
    { data: ListUsersManagementApiResult[] }
  >({
    resource: '',
    method: 'get',
    url: `/users-management`,
    baseURL: import.meta.env.VITE_DATAVIZ_API_URL_URL,
    params: {
      institutionId: params.institutionId,
      role: params.role,
      applicationId: params.applicationId,
      roleFilter: params.roleFilter,
      userId: params.userId
    }
  })

  return listUsersManagementSanitizer(data.data)
}

export async function listUniqueApplicationService(
  params: ListUniqueApplicationServiceParams
): Promise<ListUniqueApplicationFormattedServiceResult> {
  const { data } = await hubApiService<
    ListUniqueApplicationApiParams,
    ListUniqueApplicationApiResult
  >({
    resource: '',
    method: 'get',
    url: `/access/institutions/${params.institutionId}/apps/unique`,
    baseURL: import.meta.env.VITE_DATAVIZ_API_URL_URL,
    params
  })

  return listUniqueApplicationSanitizer(data)
}

export async function listAccessUniqueService(
  params: ListAccessUniqueServiceParams
): Promise<ListAccessUniqueFormattedServiceResult> {
  const { data } = await hubApiService<ListAccessUniqueApiParams, ListAccessUniqueApiResult>({
    resource: '',
    method: 'get',
    url: `/access/institutions/${params.institutionId}/all`,
    baseURL: import.meta.env.VITE_DATAVIZ_API_URL_URL,
    params
  })

  return listAccessUniqueSanitizer(data)
}

// export async function listAccessTotalService(
//   params: ListAccessTotalServiceParams
// ): Promise<ListAccessTotalFormattedServiceResult> {
//   const { data } = await hubApiService<ListAccessTotalApiParams, ListAccessTotalApiResult>({
//     resource: '',
//     method: 'get',
//     url: `/access/institutions/${params.institutionId}/total`,
//     baseURL: import.meta.env.VITE_DATAVIZ_API_URL_URL,
//     params
//   })

//   return listAccessTotalSanitizer(data)
// }

export async function listAccessApplicationUniqueService(
  params: ListAccessApplicationUniqueServiceParams
): Promise<ListAccessApplicationUniqueServiceResult> {
  const { data } = await hubApiService<
    ListAccessApplicationApiParams,
    ListAccessApplicationApiResult
  >({
    resource: '',
    method: 'get',
    url: `/access/institutions/${params.institutionId}/apps/all`,
    baseURL: import.meta.env.VITE_DATAVIZ_API_URL_URL,
    params
  })

  return listAccessApplicationUniqueSanitizer(data)
}

export async function listSchoolAccessService(
  params: ListSchoolAccessServiceParams
): Promise<ListSchoolAccessServiceResult> {
  const { data } = await hubApiService<ListSchoolAccessApiParams, ListSchoolAccessApiResult>({
    resource: '',
    method: 'get',
    url: `/access/app/${params.applicationId}/institutions/unique`,
    baseURL: import.meta.env.VITE_DATAVIZ_API_URL_URL,
    params
  })

  return ListSchoolAccessSanitizer(data)
}

export async function listAccessApplicationUniqueRolesService(
  params: ListAccessApplicationUniqueRolesServiceParams
): Promise<ListAccessApplicationUniqueRolesServiceResult> {
  const { data } = await hubApiService<
    ListAccessApplicationUniqueRolesApiParams,
    ListAccessApplicationUniqueRolesApiResult
  >({
    resource: '',
    method: 'get',
    url: `/access/institutions/${params.institutionId}/apps/roles`,
    baseURL: import.meta.env.VITE_DATAVIZ_API_URL_URL,
    params
  })

  return listAccessApplicationUniqueRolesSanitizer(data)
}

export async function listChildrenService({
  institutionId
}: ListChildrenServiceParams): Promise<ListChildrenServiceResult> {
  const { data } = await hubApiService<null, { data: ListChildrenApiResult }>({
    resource: '',
    method: 'get',
    url: `/institutions/${institutionId}/children`,
    baseURL: import.meta.env.VITE_DATAVIZ_API_URL_URL
  })

  return listChildrenSanitizer(data.data)
}

export async function listAccessApplicationSchoolRolesService(
  params: ListAccessApplicationSchoolRolesServiceParams
): Promise<ListAccessApplicationSchoolRolesServiceResult> {
  const { data } = await hubApiService<
    ListAccessApplicationSchoolRolesApiParams,
    ListAccessApplicationSchoolRolesApiResult
  >({
    resource: '',
    method: 'get',
    url: `/access/institutions/${params.institutionId}/roles`,
    baseURL: import.meta.env.VITE_DATAVIZ_API_URL_URL,
    params
  })

  return listAccessApplicationSchoolRolesSanitizer(data)
}
