import { yupResolver } from '@hookform/resolvers/yup'
import { Badges } from '@positivote/design-system/components/Badges'
import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Button } from '@positivote/design-system/components/Button'
import { Chip } from '@positivote/design-system/components/Chip'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Typography } from '@positivote/design-system/components/Typography'
import { UL } from '@positivote/design-system/components/UL'
import { FormAutocomplete } from '@positivote/design-system/components-form/Autocomplete'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormSelect } from '@positivote/design-system/components-form/Select'
import { CheckCircleIcon } from '@positivote/design-system/icons/CheckCircle'
import { DownloadIcon } from '@positivote/design-system/icons/Download'
import { TodayIcon } from '@positivote/design-system/icons/Today'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { AccessTimeIcon } from '@/fixme/icons/AccessTime'
import { AcademicSyncForm } from '@/modules/hub/academic-sync/contracts/form'
import { useExportAcademicSync, useListExportAcademicSync } from '@/modules/hub/academic-sync/hooks'
import { dataExports } from '@/modules/hub/academic-sync/sanitizers'
import { academicSync } from '@/modules/hub/academic-sync/validations'
import { useAuth } from '@/modules/hub/auth/contexts'
import { OrganizationKind } from '@/modules/hub/organizations/contracts'
import { SchoolYearFormatted } from '@/modules/hub/school-year/contracts'
import { useListSchoolYear } from '@/modules/hub/school-year/hooks'

interface SelectOption {
  id: string
  icon: JSX.Element
  title: string
}

export function ListExport(): JSX.Element {
  changePageTitle(i18n().modules.hub.academicSync.pages.listExport.pageTitle)
  const navigate = useNavigate()
  const { profile } = useAuth()
  const exportAcademicSync = useExportAcademicSync()
  const [selectedSchoolYear, setSelectedSchoolYear] = useState<SchoolYearFormatted | null>(null)
  const location = useLocation()
  const locationState = location.state as { idSchool?: string; schoolName?: string } | undefined
  const isSchool = profile?.organization.kindId === OrganizationKind.SCHOOL

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<AcademicSyncForm>({
    mode: 'onSubmit',
    resolver: yupResolver(academicSync)
  })
  const currentOrgId = locationState?.idSchool ?? profile?.organizationId

  const listSchoolYear = useListSchoolYear({
    model: {
      perPage: 100,
      institutionId: currentOrgId as unknown as string
    }
  })

  const listExport = useListExportAcademicSync({
    model: {
      orgId: currentOrgId as unknown as number
    },
    queryOptions: {
      refetchInterval: 10000,
      enabled: !!currentOrgId
    }
  })

  const filteredSchoolYearOptions = useMemo<SelectOption[]>(() => {
    return (
      listSchoolYear.data?.registers.map((year) => ({
        id: year.code,
        icon: <Badges fill={year.status === 'active' ? '$success' : '$neutral-70'} />,
        title: year.title
      })) ?? []
    )
  }, [listSchoolYear.data?.registers])

  function onSubmit(model: AcademicSyncForm): void {
    exportAcademicSync.mutate({
      model: {
        filter: {
          data: model.data.join(', ')
        },
        orgId: currentOrgId as unknown as number,
        schoolYearCode: model.schoolYearId
      }
    })
  }

  useEffect(() => {
    setSelectedSchoolYear(listSchoolYear.data?.registers[0] ?? null)
    setValue('schoolYearId', listSchoolYear.data?.registers[0].code ?? '')
  }, [listSchoolYear.data?.registers])

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        title={i18n().modules.hub.academicSync.pages.listExport.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={
          isSchool
            ? [
                {
                  label:
                    i18n().modules.hub.academicSync.pages.listExport.appBar.breadcrumbs.overview,
                  onClick: () => {
                    navigate(-1)
                  }
                },
                {
                  label:
                    i18n().modules.hub.academicSync.pages.listExport.appBar.breadcrumbs.myExports
                }
              ]
            : [
                {
                  label: i18n().modules.hub.disciplines.pages.list.appBar.breadcrumbs.overview,
                  onClick: () => {
                    navigate(-1)
                  }
                },
                {
                  label:
                    i18n().modules.hub.academicSync.pages.listExport.appBar.breadcrumbs.myExports
                },
                {
                  label: locationState?.schoolName ?? '',
                  onClick: () => {
                    navigate(-1)
                  }
                }
              ]
        }
      />
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '$lg',
          overflowY: 'auto',
          gap: '$lg',
          '@sm': { padding: '$md' }
        }}
      >
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            borderWidth: '$thin',
            borderStyle: 'dashed',
            borderRadius: '$md',
            borderColor: '$outline-variant',
            padding: '$lg',
            gap: '$md'
          }}
        >
          <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
            {i18n().modules.hub.academicSync.pages.listExport.generateExportFile}
          </Typography>
          <FormContainer
            formHandleSubmit={handleSubmit}
            onSubmit={onSubmit}
            css={{ display: 'flex', gap: '$lg' }}
          >
            <FormSelect
              name="schoolYearId"
              control={control}
              leadingElement={
                listSchoolYear.isFetching ? undefined : (
                  <Badges
                    fill={selectedSchoolYear?.status === 'active' ? '$success' : '$neutral-70'}
                  />
                )
              }
              label={i18n().modules.hub.academicSync.pages.listExport.selectSchoolYear}
              leadingElementField="icon"
              data-testid="classLevel"
              optionKeyField="id"
              optionTitleField="title"
              options={filteredSchoolYearOptions}
              variant="outlined"
              gridProps={{ xl: 2, lg: 3 }}
            />
            <FormAutocomplete
              name="data"
              control={control}
              label={i18n().modules.hub.academicSync.pages.listExport.selectDataExport}
              variant="outlined"
              options={dataExports}
              optionKeyField="key"
              optionTitleField="label"
              multiple
              withCheckbox
              gridProps={{ xl: 7, lg: 6 }}
              errorText={errors.data?.message}
            />
            <Button isLoading={exportAcademicSync.isPending} type="submit">
              {i18n().modules.hub.academicSync.pages.listExport.generateFile}
            </Button>
          </FormContainer>
        </Div>

        {listExport.isFetching && !listExport.data?.length ? (
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              padding: '$lg',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Loader size={80} />
          </Div>
        ) : (
          !!listExport.data?.length && (
            <Div
              css={{
                display: 'flex',
                flexDirection: 'column',
                borderWidth: '$thin',
                borderStyle: 'solid',
                borderRadius: '$md',
                borderColor: '$outline-variant',
                padding: '$lg',
                gap: '$md'
              }}
            >
              <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                {i18n().modules.hub.academicSync.pages.listExport.exportHistory}
              </Typography>
              <Grid spacing="$md" css={{ padding: '$md' }}>
                <Grid xl={3} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                  <Typography
                    data-testid="Typography-header-discipline"
                    variant="titleMedium"
                    lineClamp={1}
                    css={{ color: '$on-surface' }}
                  >
                    {i18n().modules.hub.academicSync.pages.listExport.creationDate}
                  </Typography>
                </Grid>

                <Grid xl={2}>
                  <Typography
                    data-testid="Typography-header-code"
                    variant="titleMedium"
                    lineClamp={1}
                    css={{ color: '$on-surface' }}
                  >
                    {i18n().modules.hub.academicSync.pages.listExport.schoolYear}
                  </Typography>
                </Grid>
                <Grid xl={4}>
                  <Typography
                    data-testid="Typography-header-base-discipline"
                    variant="titleMedium"
                    lineClamp={1}
                    css={{ color: '$on-surface' }}
                  >
                    {i18n().modules.hub.academicSync.pages.listExport.selectedData}
                  </Typography>
                </Grid>
                <Grid xl={3} css={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Typography
                    data-testid="Typography-header-base-discipline"
                    variant="titleMedium"
                    lineClamp={1}
                    css={{ color: '$on-surface' }}
                  >
                    {i18n().modules.hub.academicSync.pages.listExport.status}
                  </Typography>
                </Grid>
              </Grid>
              <UL
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '$sm'
                }}
              >
                {listExport.data.map((listAcademicSync) => (
                  <BaseCard
                    key={listAcademicSync.id}
                    css={{
                      borderRadius: '$lg',
                      backgroundColor: '$surface-1',
                      '& .BaseCard-StateLayer': {
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '$lg',
                        padding: '$md'
                      }
                    }}
                  >
                    <Grid spacing="$md" css={{ flex: 1, alignItems: 'center' }}>
                      <Grid xl={3} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                        <Div css={{ display: 'flex', gap: '$2xs' }}>
                          <TodayIcon size={16} />
                          <Typography
                            lineClamp={2}
                            variant="bodyMedium"
                            css={{ color: '$on-surface' }}
                          >
                            {listAcademicSync.dateFormatted}
                          </Typography>
                        </Div>
                        <Div css={{ display: 'flex', gap: '$2xs' }}>
                          <AccessTimeIcon size={16} />
                          <Typography
                            lineClamp={2}
                            variant="bodyMedium"
                            css={{ color: '$on-surface' }}
                          >
                            {listAcademicSync.timeFormatted}
                          </Typography>
                        </Div>
                      </Grid>

                      <Grid xl={2}>
                        <Typography
                          lineClamp={2}
                          variant="bodyMedium"
                          css={{ color: '$on-surface' }}
                        >
                          {listAcademicSync.schoolYearName}
                        </Typography>
                      </Grid>
                      <Grid xl={4} css={{ display: 'flex', gap: '$sm', flexWrap: 'wrap' }}>
                        {listAcademicSync.filtroFormatted.map((role, index) => (
                          <Chip
                            key={index}
                            label={role}
                            color="neutral"
                            variant="outlined"
                            data-testid={`listItemProfile-${index}`}
                          />
                        ))}
                      </Grid>
                      <Grid
                        xl={3}
                        css={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          gap: '$md'
                        }}
                      >
                        <Div
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '$md'
                          }}
                        >
                          <Div
                            css={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              position: 'relative'
                            }}
                          >
                            {listAcademicSync.status === 'aguardando_processamento' ||
                            listAcademicSync.status === 'processando' ? (
                              <Loader size={20} css={{ marginRight: '$lg' }} />
                            ) : (
                              <CheckCircleIcon fill="$success" size={20} />
                            )}
                          </Div>
                          <IconButton
                            onClick={() => window.open(`${listAcademicSync.arquivo}`, '_self')}
                            variant="standard"
                            disabled={
                              listAcademicSync.status === 'aguardando_processamento' ||
                              listAcademicSync.status === 'processando'
                            }
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Div>
                      </Grid>
                    </Grid>
                  </BaseCard>
                ))}
              </UL>
            </Div>
          )
        )}
      </Div>
    </Main>
  )
}
