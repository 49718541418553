export const academicSyncPt = {
  resources: {
    listAcademicSync: 'Listar sincronização acadêmica',
    showAcademicSync: 'Exibir sincronização acadêmica',
    exportAcademicSync: 'Exportar sincronização acadêmica ',
    listExportAcademicSync: 'Listar exportações sincronização acadêmica '
  },
  components: {
    status: 'Status',
    line: 'Linha',
    code: 'Matrícula *',
    codeDiscipline: 'Código da disciplina *',
    codeSchoolClass: 'Código da turma *',
    nameDiscipline: 'Nome da Disciplina *',
    baseDiscipline: 'Disciplina base *',
    codeStudent: 'Matrícula do aluno *',
    codeTeacher: 'Matrícula do professor *',
    fullName: 'Nome completo *',
    birthday: 'Data Nascimento *',
    email: 'E-mail',
    gender: 'Gênero',
    cpf: 'CPF',
    login: 'Login *',
    schoolYear: 'Ano escolar *',
    schoolClassName: 'Nome da turma *',
    emptyListDiscipline: 'Não há disciplinas para exibir',
    emptyListEnrollmentStudent: 'Não há vínculos com estudantes para exibir',
    emptyListEnrollmentTeacher: 'Não há vínculos com professores para exibir',
    emptyListGuardian: 'Não há responsáveis para exibir',
    emptyListSchoolClass: 'Não há turmas para exibir',
    emptyListStaff: 'Não há colaborador para exibir',
    emptyListStudent: 'Não há estudantes para exibir',
    emptyListTeacher: 'Não há professores para exibir'
  },
  pages: {
    list: {
      pageTitle: 'Minhas importações',
      allYear: 'Todos os anos',
      readingInformation: 'Lendo informações...',
      adaptingTables: 'Adaptando tabelas...',
      processingData: 'Processando dados...',
      importData: 'Importar dados',
      viewData: 'Visualizar dados',
      processedIn: 'Processado em:',
      sender: 'Enviado por:',
      fileStatus: 'Status do arquivo:',
      totalUser: 'Total de usuários:',
      totalLinks: 'Total de vínculos:',
      importHistory: 'Histórico de importações',
      filteringOptions: 'Opções de filtragem',
      schoolYear: 'Ano letivo',
      importInUse: 'Importação em uso',
      file: 'Arquivo',
      shipping: 'Envio',
      user: 'Usuário',
      users: 'Usuários',
      associations: 'associações',
      status: 'Status',
      messageStatus: {
        success: 'Dados corretos',
        error: 'Dados ausentes ou incorretos',
        warning: 'Dados que precisam de atenção'
      },
      emptyList: {
        title: 'Ops! Ainda não temos arquivos importados para exibir',
        subTitle:
          'Adicione um arquivo de planilha com os dados da sua instituição clicando abaixo.Formato:*.xls'
      },
      dialog: {
        title: 'Iniciar importação',
        ContentText: 'Indique o ano letivo no qual deseja incluir os dados:',
        selectLabel: 'Selecione o ano letivo',
        contentTextFooter: 'Agora, selecione o arquivo com a planilha de dados da sua escola:',
        footerRefuseButton: 'Cancelar',
        footerAcceptButton: 'Selecionar arquivo'
      },
      appBar: {
        title: 'Gerenciamento de dados',
        breadcrumbs: {
          overview: 'Visão geral',
          myImports: 'Minhas importações',
          selectedInstitution: 'Selecione uma instituição'
        },
        buttonDownloadSpreadsheet: 'Baixar modelo de planilha',
        buttonImportSpreadsheet: 'Importar Planilha'
      },
      alertLabel: 'Importação enviada com sucesso'
    },
    details: {
      pageTitle: (name: string): string => `Visualizar ${name}`,
      filterStatus: {
        allStatus: 'Todos os status',
        success: 'Dados corretos',
        error: 'Dados ausentes ou incorretos'
      },
      filteringOptions: 'Opções de filtragem',
      filterByStatus: 'Filtrar por status',
      students: 'Estudantes',
      disciplines: 'Disciplinas',
      classes: 'Turmas',
      responsible: 'Responsáveis',
      teachers: 'Professores',
      teacherLinks: 'Vínculos professores',
      collaborators: 'Colaboradores',
      studentsLinks: 'Vínculos Estudantes',
      appBar: {
        title: 'Gerenciamento de dados',
        breadcrumbs: {
          overview: 'Visão geral',
          myImports: 'Minhas importações',
          file: 'Arquivo'
        },
        buttonDownloadSpreadsheet: 'Baixar modelo de planilha',
        buttonImportSpreadsheet: 'Importar Planilha'
      }
    },
    listExport: {
      pageTitle: 'Minhas exportações',
      generateExportFile: 'Gerar arquivo de exportação',
      selectSchoolYear: 'Selecione o ano letivo',
      selectDataExport: 'Selecione os dados para exportação',
      generateFile: 'Gerar arquivo',
      exportHistory: 'Histórico de exportações',
      creationDate: 'Data de criação',
      schoolYear: 'Ano letivo',
      selectedData: 'Dados selecionados',
      status: 'Status',

      appBar: {
        title: 'Gerenciamento de dados',
        breadcrumbs: {
          overview: 'Visão geral',
          myExports: 'Minhas exportações'
        }
      }
    }
  }
}
