import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Select } from '@positivote/design-system/components/Select'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormDatePicker } from '@positivote/design-system/components-form/DatePicker'
import { FormSelect } from '@positivote/design-system/components-form/Select'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { FilterListIcon } from '@positivote/design-system/icons/FilterList'
import { InfoIcon } from '@positivote/design-system/icons/Info'
import { NavigateBeforeIcon } from '@positivote/design-system/icons/NavigateBefore'
import { NavigateNextIcon } from '@positivote/design-system/icons/NavigateNext'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { useEffect, useState } from 'react'
// eslint-disable-next-line import/default
import ReactApexChart from 'react-apexcharts'
import { useForm } from 'react-hook-form'

import { convertDateToISO } from '@/common/helpers'
// import { ShowChartIcon } from '@/fixme/icons/ShowChart'
// import { TableChartIcon } from '@/fixme/icons/TableChart'
import { ButtonGroup } from '@/fixme/component/ButtonGroup'
import { SchoolOrgIcon } from '@/fixme/icons/SchoolOrg'
import { useAuth } from '@/modules/hub/auth/contexts'
import { allClassLevelsWithFinalValue } from '@/modules/hub/classroom/constants'
import { OrganizationKind } from '@/modules/hub/organizations/contracts'

import {
  defaultBarGraphics,
  defaultLineGraphics,
  defaultSimpleBarGraphics
  // defaultSimpleBarGraphics
} from '../../constants/graphics'
import { BaseFilterAccess } from '../../contracts/form'
import {
  ListAccessApplicationHookParams,
  ListSchoolAccessHookParams,
  ListUniqueApplicationHookResult
} from '../../contracts/hook'
import { defaultRoles } from '../../contracts/model'
import { AccessBaseFilter } from '../../contracts/service'
import {
  useAccessApplicationUnique,
  useListAccessApplicationSchoolRoles,
  useListAccessApplicationUniqueRoles,
  useListAccessUnique,
  useListApplication,
  useListChildren,
  useListSchoolAccess,
  useListUniqueApplication
} from '../../hooks'
import { filterFormeSchema } from '../../validations'

export function Access(): JSX.Element {
  const { profile } = useAuth()
  const isSchool = profile?.organization.kindId === OrganizationKind.SCHOOL
  const [showFilter, setShowFilter] = useState(false)
  const [accessChangeButton, setAccessChangeButton] = useState(0)
  const [accessApplicationChangeButton, setAccessApplicationChangeButton] = useState(0)
  const [schoolAccessChangeButton, setSchoolAccessChangeButton] = useState(0)
  const [singleAccessApplicationChangeButton, setSingleAccessApplicationChangeButton] = useState(0)
  const [uniqueAccessesSchoolsChangeButton, setUniqueAccessesSchoolsChangeButton] = useState(0)

  const [baseFilter, setBaseFilter] = useState<AccessBaseFilter>()

  const [accessApplicationParams, setAccessApplicationParams] = useState<
    ListAccessApplicationHookParams['model']
  >({
    firstApp: '',
    secondApp: '',
    institutionId: profile?.organization.code as unknown as string
  })

  const [newApplications, setNewApplications] = useState<
    ListUniqueApplicationHookResult | undefined
  >()

  const [firstSchoolAccessApplicationUniqueParams, setFirstSchoolAccessApplicationUniqueParams] =
    useState<ListSchoolAccessHookParams['model']>({
      firstInstitutionId: '',
      secondInstitutionId: '',
      applicationId: ''
    })

  const { control, handleSubmit, formState } = useForm<BaseFilterAccess>({
    mode: 'onSubmit',
    resolver: yupResolver(filterFormeSchema),
    defaultValues: {
      roleFilter: 'all',
      schoolLevel: 'all',
      startDate: undefined,
      endDate: undefined,
      institutionId: 'all'
    }
  })

  const listUniqueApplication = useListUniqueApplication({
    model: {
      institutionId: profile?.organization.code as unknown as string,
      ...baseFilter,
      endDate: baseFilter?.endDate ? convertDateToISO(baseFilter.endDate ?? '') : undefined,
      startDate: baseFilter?.startDate ? convertDateToISO(baseFilter.startDate ?? '') : undefined
    }
  })

  useEffect(() => {
    setNewApplications(listUniqueApplication.data)
  }, [listUniqueApplication.data])

  const listApplications = useListApplication({
    model: {
      institutionId: profile?.organization.code as unknown as string
    }
  })

  const listAccessUnique = useListAccessUnique({
    model: {
      institutionId: profile?.organization.code as unknown as string,
      ...baseFilter,
      endDate: baseFilter?.endDate ? convertDateToISO(baseFilter.endDate ?? '') : undefined,
      startDate: baseFilter?.startDate ? convertDateToISO(baseFilter.startDate ?? '') : undefined
    }
  })

  // const listAccessTotal = useListAccessTotal({
  //   model: {
  //     institutionId: profile?.organization.code as unknown as string,
  //     ...baseFilter,
  //     endDate: baseFilter?.endDate ? convertDateToISO(baseFilter.endDate ?? '') : undefined,
  //     startDate: baseFilter?.startDate ? convertDateToISO(baseFilter.startDate ?? '') : undefined
  //   }
  // })

  const firstAccessApplicationUnique = useAccessApplicationUnique({
    model: {
      ...accessApplicationParams,
      ...baseFilter,
      endDate: baseFilter?.endDate ? convertDateToISO(baseFilter.endDate ?? '') : undefined,
      startDate: baseFilter?.startDate ? convertDateToISO(baseFilter.startDate ?? '') : undefined
    },
    queryOptions: {
      enabled:
        !!accessApplicationParams.firstApp.length && !!accessApplicationParams.secondApp.length
    }
  })

  const listAccessSchool = useListSchoolAccess({
    model: {
      ...firstSchoolAccessApplicationUniqueParams,
      ...baseFilter,
      institutionId: firstSchoolAccessApplicationUniqueParams.institutionId,
      applicationId: firstSchoolAccessApplicationUniqueParams.applicationId,
      endDate: baseFilter?.endDate ? convertDateToISO(baseFilter.endDate ?? '') : undefined,
      startDate: baseFilter?.startDate ? convertDateToISO(baseFilter.startDate ?? '') : undefined
    },
    queryOptions: {
      enabled:
        !!firstSchoolAccessApplicationUniqueParams.firstInstitutionId.length &&
        !!firstSchoolAccessApplicationUniqueParams.secondInstitutionId.length &&
        !!firstSchoolAccessApplicationUniqueParams.applicationId.length
    }
  })

  function nextCard(): void {
    if (newApplications?.dataFormatted.length) {
      const updatedDataFormatted = [...newApplications.dataFormatted]
      const firstElement = updatedDataFormatted.shift()
      if (firstElement !== undefined) {
        setNewApplications((oldData) => {
          if (!oldData) {
            return oldData
          }

          return {
            ...oldData,
            dataFormatted: [...updatedDataFormatted, firstElement]
          }
        })
      }
    }
  }

  function beforeCard(): void {
    if (newApplications?.dataFormatted.length) {
      const updatedDataFormatted = [...newApplications.dataFormatted]

      const lastElement = updatedDataFormatted.pop()

      if (lastElement !== undefined) {
        setNewApplications((oldData) => {
          if (!oldData) {
            return oldData
          }

          return {
            ...oldData,
            dataFormatted: [lastElement, ...updatedDataFormatted]
          }
        })
      }
    }
  }

  const listAccessApplicationUniqueRoles = useListAccessApplicationUniqueRoles({
    model: {
      institutionId: profile?.organization.code as unknown as string,
      ...baseFilter,
      endDate: baseFilter?.endDate ? convertDateToISO(baseFilter.endDate ?? '') : undefined,
      startDate: baseFilter?.startDate ? convertDateToISO(baseFilter.startDate ?? '') : undefined
    }
  })

  const listAccessApplicationSchoolRoles = useListAccessApplicationSchoolRoles({
    model: {
      institutionId: profile?.organization.code as unknown as string,
      ...baseFilter,
      endDate: baseFilter?.endDate ? convertDateToISO(baseFilter.endDate ?? '') : undefined,
      startDate: baseFilter?.startDate ? convertDateToISO(baseFilter.startDate ?? '') : undefined
    }
  })

  useEffect(() => {
    setAccessApplicationParams((oldData) => ({
      ...oldData,
      firstApp: String(listApplications.data?.[0]?.id || ''),
      secondApp: String(listApplications.data?.[1]?.id || '')
    }))

    setFirstSchoolAccessApplicationUniqueParams((oldData) => ({
      ...oldData,
      applicationId: String(listApplications.data?.[0]?.id || '')
    }))
  }, [listApplications.data])

  function handleFilter(data: BaseFilterAccess): void {
    setBaseFilter((oldData) => ({
      ...oldData,
      ...data,
      roleFilter: data.roleFilter === 'all' ? undefined : data.roleFilter,
      schoolLevel: data.schoolLevel === 'all' ? undefined : data.schoolLevel,
      institutionId:
        data.institutionId === 'all'
          ? (profile?.organization.code as unknown as string)
          : data.institutionId
    }))
  }

  const isLoading =
    listUniqueApplication.isFetching ||
    listAccessUnique.isFetching ||
    // firstAccessApplicationUnique.isFetching ||
    // secondAccessApplicationUnique.isFetching ||
    // firstAccessApplicationTotal.isFetching ||
    // secondAccessApplicationTotal.isFetching ||
    listAccessApplicationUniqueRoles.isFetching ||
    listAccessApplicationSchoolRoles.isFetching
  // firstSchoolAccessApplicationUnique.isFetching ||
  // secondSchoolAccessApplicationUnique.isFetching

  useEffect(() => {
    if (!isLoading) {
      setShowFilter(true)
    }
  }, [isLoading])

  const allClassLevelsWithFinalValueFormatted = [
    { key: 'all', value: 'Todos os anos escolares' },
    ...allClassLevelsWithFinalValue
  ]

  const listChildren = useListChildren({
    model: {
      institutionId: profile?.organization.code as unknown as string
    },
    onSuccess: (data) => {
      setFirstSchoolAccessApplicationUniqueParams((oldData) => ({
        ...oldData,
        firstInstitutionId: data.data[0].codHub,
        secondInstitutionId: data.data[1].codHub
      }))
    },
    queryOptions: {
      enabled: !isSchool
    }
  })

  const listChildrenFormatted = [
    { codHub: 'all', instituicao: 'Todas as escolas' },
    ...(listChildren.data?.data ?? [])
  ]

  const listApplicationsFormatted = [
    { id: 0, name: 'Todas as aplicações' },
    ...(listApplications.data ?? [])
  ]

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '$lg',
          overflowY: 'auto',
          gap: '$lg',
          '@sm': { padding: '$md' }
        }}
      >
        {isLoading && !showFilter ? (
          <Div
            css={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Loader size={80} />
          </Div>
        ) : (
          <>
            {showFilter && (
              <FormContainer formHandleSubmit={handleSubmit} onSubmit={handleFilter}>
                <Div
                  css={{ display: 'flex', alignItems: 'center', gap: '$2xs', marginBottom: '$md' }}
                >
                  <FilterListIcon size={18} />
                  <Typography
                    data-testid="Typography-titleFilter"
                    variant="titleMedium"
                    css={{ color: '$on-surface' }}
                  >
                    Opções de filtragem
                  </Typography>
                </Div>

                <Grid spacing="$lg" css={{ width: 'unset', flex: 1 }}>
                  <Grid xl={4}>
                    <FormDatePicker
                      name="startDate"
                      control={control}
                      label="Data de ínicio"
                      variant="outlined"
                      errorText={formState.errors.startDate?.message}
                    />
                  </Grid>
                  <Grid xl={4}>
                    <FormDatePicker
                      name="endDate"
                      control={control}
                      label="Data de término"
                      variant="outlined"
                      errorText={formState.errors.endDate?.message}
                    />
                  </Grid>
                  <Grid xl={4}>
                    <FormSelect
                      name="roleFilter"
                      control={control}
                      label="Selecione o tipo de usuário"
                      variant="outlined"
                      optionKeyField="key"
                      optionTitleField="name"
                      value={baseFilter?.roleFilter}
                      options={defaultRoles}
                    />
                  </Grid>
                  {!isSchool && (
                    <Grid xl={4}>
                      <FormSelect
                        name="institutionId"
                        control={control}
                        label="Selecionar instituição"
                        variant="outlined"
                        optionKeyField="codHub"
                        optionTitleField="instituicao"
                        options={listChildrenFormatted}
                      />
                    </Grid>
                  )}

                  <Grid xl={isSchool ? 6 : 4}>
                    <FormSelect
                      name="schoolLevel"
                      control={control}
                      label="Selecionar o ano escolar"
                      variant="outlined"
                      optionKeyField="key"
                      optionTitleField="value"
                      options={allClassLevelsWithFinalValueFormatted}
                    />
                  </Grid>
                  <Grid xl={isSchool ? 6 : 4}>
                    <FormTextField
                      name="search"
                      control={control}
                      data-testid="searchBy"
                      label="Pesquisar por estudante"
                      variant="outlined"
                      leadingIcon={{ icon: SearchIcon }}
                    />
                  </Grid>
                  <Grid xl={12} css={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                      isLoading={isLoading}
                      type="submit"
                      LeadingIcon={<FilterListIcon />}
                      variant="outlined"
                    >
                      Aplicar filtros
                    </Button>
                  </Grid>
                </Grid>
              </FormContainer>
            )}

            {isLoading && showFilter ? (
              <Div
                css={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Loader size={80} />
              </Div>
            ) : (
              <>
                <Typography variant="titleLarge">Acessos por aplicação</Typography>
                <Div css={{ display: 'flex', gap: '$lg' }}>
                  <IconButton
                    onClick={beforeCard}
                    disabled={newApplications?.data && newApplications.data.length <= 2}
                    variant="outlined"
                    css={{
                      minHeight: 458
                    }}
                  >
                    <NavigateBeforeIcon size={32} />
                  </IconButton>

                  {newApplications?.dataFormatted.slice(0, 2).map((application) => (
                    <Div
                      key={application.name}
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '$lg',
                        gap: '$md',
                        borderWidth: '$thin',
                        borderStyle: 'solid',
                        borderColor: '$outline-variant',
                        padding: '$lg',
                        flex: 1
                      }}
                    >
                      <Div
                        css={{
                          display: 'flex',
                          gap: '$sm',
                          alignItems: 'center'
                        }}
                      >
                        <Div
                          css={{
                            display: 'flex',
                            gap: '$sm',
                            alignItems: 'center',
                            flex: 1
                          }}
                        >
                          <Image
                            alt="2"
                            height={32}
                            width={32}
                            src={application.icone}
                            css={{
                              borderRadius: '$full',
                              borderWidth: '$thin',
                              borderStyle: 'solid',
                              borderColor: '$outline-variant'
                            }}
                          />
                          <Typography variant="titleMedium">{application.name}</Typography>
                        </Div>
                        <Tooltip label="Representa uma sessão individual de um usuário dentro da plataforma. Geralmente, refere-se ao acesso de um único dispositivo, navegador ou conta ativa em determinado momento.">
                          <InfoIcon />
                        </Tooltip>
                      </Div>
                      <Div
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '$sm',
                          backgroundColor: '$surface-2',
                          padding: '$md',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '$md'
                        }}
                      >
                        <Typography variant="titleMedium">Acessos únicos</Typography>
                        <Div
                          css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                        >
                          <Typography variant="displayMedium">{application.total}</Typography>
                          <Typography variant="bodySmall">quantidade total</Typography>
                        </Div>
                      </Div>
                      <ReactApexChart
                        options={{
                          ...defaultLineGraphics.options,
                          colors: ['#8BC34A'],
                          yaxis: {
                            title: {
                              text: 'Acessos Únicos',
                              style: {
                                fontSize: '10px',
                                fontWeight: 500,
                                fontFamily: 'Roboto',
                                color: '#5D6675'
                              }
                            }
                          },
                          xaxis: {
                            ...defaultLineGraphics.options.xaxis,
                            categories: application.xaxisTotalFormatted
                          }
                        }}
                        series={[{ name: 'Acessos', data: application.seriesDateFormatted }]}
                        type="line"
                        width="100%"
                        height={180}
                      />
                    </Div>
                  ))}
                  <IconButton
                    disabled={newApplications?.data && newApplications.data.length <= 2}
                    onClick={nextCard}
                    variant="outlined"
                    css={{ minHeight: 458 }}
                  >
                    <NavigateNextIcon size={32} />
                  </IconButton>
                </Div>
                <Div
                  css={{
                    flexDirection: 'column',
                    display: 'flex',
                    gap: '$lg',
                    padding: '$lg',
                    borderRadius: '$lg',
                    borderWidth: '$thin',
                    borderStyle: 'solid',
                    borderColor: '$outline-variant'
                  }}
                >
                  <Div
                    css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <Typography variant="titleLarge">Acessos ao Hub Educacional</Typography>
                    <ButtonGroup onChangeButton={(pos) => setAccessChangeButton(pos)} />
                  </Div>

                  {accessChangeButton === 0 ? (
                    <Div
                      css={{
                        display: 'flex',
                        flex: 1,
                        gap: '$lg',
                        position: 'relative',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: 280
                      }}
                    >
                      <Div
                        css={{
                          width: '100%',
                          padding: '$lg',
                          borderRadius: '$lg',
                          borderWidth: '$thin',
                          borderStyle: 'solid',
                          borderColor: '$outline-variant',
                          position: 'relative'
                        }}
                      >
                        <Div
                          css={{
                            position: 'absolute',
                            top: 24,
                            right: 24,
                            zIndex: 1
                          }}
                        >
                          <Tooltip label="Representa uma sessão individual de um usuário dentro da plataforma. Geralmente, refere-se ao acesso de um único dispositivo, navegador ou conta ativa em determinado momento.">
                            <InfoIcon />
                          </Tooltip>
                        </Div>

                        <ReactApexChart
                          options={{
                            ...defaultLineGraphics.options,
                            title: {
                              ...defaultLineGraphics.options.title,
                              text: 'Acessos únicos'
                            },
                            colors: ['#2196F3'],
                            xaxis: {
                              ...defaultLineGraphics.options.xaxis,
                              categories: listAccessUnique.data?.dateFormatted
                            }
                          }}
                          series={[
                            {
                              name: 'Acessos',
                              data: listAccessUnique.data?.seriesUniqueFormatted ?? []
                            }
                          ]}
                          type="line"
                          width="100%"
                          height={280}
                        />
                      </Div>
                      <Div
                        css={{
                          width: '100%',
                          padding: '$lg',
                          borderRadius: '$lg',
                          borderWidth: '$thin',
                          borderStyle: 'solid',
                          borderColor: '$outline-variant',
                          position: 'relative'
                        }}
                      >
                        <Div
                          css={{
                            position: 'absolute',
                            top: 24,
                            right: 24,
                            zIndex: 1
                          }}
                        >
                          <Tooltip label="Representa o número total de vezes que uma plataforma foi acessada dentro de um período, podendo incluir múltiplos acessos do mesmo usuário.">
                            <InfoIcon />
                          </Tooltip>
                        </Div>

                        <ReactApexChart
                          options={{
                            ...defaultLineGraphics.options,
                            title: {
                              ...defaultLineGraphics.options.title,
                              text: 'Total de acessos'
                            },
                            colors: ['#A631BA'],
                            xaxis: {
                              ...defaultLineGraphics.options.xaxis,
                              categories: listAccessUnique.data?.dateFormatted
                            }
                          }}
                          series={[
                            {
                              name: 'Acessos',
                              data: listAccessUnique.data?.seriesTotalFormatted ?? []
                            }
                          ]}
                          type="line"
                          width="100%"
                          height={280}
                        />
                      </Div>
                    </Div>
                  ) : (
                    <Div
                      css={{
                        borderRadius: '$md',
                        borderWidth: '$thin',
                        borderStyle: 'solid',
                        borderColor: '$outline-variant'
                      }}
                    >
                      <Div
                        css={{
                          display: 'flex',
                          flex: 1,
                          borderRadius: '$md',
                          flexDirection: 'column',
                          maxHeight: 368,
                          overflow: 'auto'
                        }}
                      >
                        <Grid
                          css={{
                            display: 'flex',
                            padding: '$md'
                          }}
                        >
                          <Grid xl={4} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                            <Typography
                              data-testid="Typography-header-discipline"
                              variant="titleSmall"
                              lineClamp={1}
                              css={{ color: '$on-surface' }}
                            >
                              Data
                            </Typography>
                          </Grid>

                          <Grid xl={4}>
                            <Typography
                              data-testid="Typography-header-code"
                              variant="titleSmall"
                              lineClamp={1}
                              css={{ color: '$on-surface' }}
                            >
                              Total de acessos
                            </Typography>
                          </Grid>
                          <Grid xl={4}>
                            <Typography
                              data-testid="Typography-header-base-discipline"
                              variant="titleSmall"
                              lineClamp={1}
                              css={{ color: '$on-surface' }}
                            >
                              Total de acessos únicos
                            </Typography>
                          </Grid>
                        </Grid>

                        <Div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            maxHeight: 368,
                            overflow: 'auto'
                          }}
                        >
                          {listAccessUnique.data?.data.map((application) => (
                            <Grid
                              key={application.date}
                              css={{
                                display: 'flex',
                                padding: '$md',
                                borderWidth: '$thin',
                                borderStyle: 'solid',
                                borderColor: '$surface-variant',
                                alignItems: 'center'
                              }}
                            >
                              <Grid xl={4}>
                                <Typography
                                  data-testid="Typography-header-code"
                                  variant="bodySmall"
                                  lineClamp={1}
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  {application.date}
                                </Typography>
                              </Grid>
                              <Grid xl={4}>
                                <Typography
                                  data-testid="Typography-header-code"
                                  variant="bodySmall"
                                  lineClamp={1}
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  {application.unique}
                                </Typography>
                              </Grid>
                              <Grid xl={4}>
                                <Typography
                                  data-testid="Typography-header-code"
                                  variant="bodySmall"
                                  lineClamp={1}
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  {application.total}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Div>
                      </Div>
                    </Div>
                  )}
                </Div>
                {profile?.role.code !== 'professor' && (
                  <Div
                    css={{
                      flexDirection: 'column',
                      display: 'flex',
                      gap: '$lg',
                      padding: '$lg',
                      borderRadius: '$lg',
                      borderWidth: '$thin',
                      borderStyle: 'solid',
                      borderColor: '$outline-variant'
                    }}
                  >
                    <Div
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant="titleLarge">
                        Comparador de acessos entre aplicações
                      </Typography>
                      <ButtonGroup
                        onChangeButton={(pos) => setAccessApplicationChangeButton(pos)}
                      />
                    </Div>
                    <Div css={{ display: 'flex', gap: '$lg', alignItems: 'center' }}>
                      <Select
                        disabled={listApplications.isFetching}
                        label="Selecione a aplicação A"
                        variant="outlined"
                        optionKeyField="id"
                        optionTitleField="name"
                        value={Number(accessApplicationParams.firstApp)}
                        options={listApplications.data ?? []}
                        css={{ width: '300px' }}
                        onChange={(option) => {
                          setAccessApplicationParams((oldData) => ({
                            ...oldData,
                            firstApp: String(option?.id)
                          }))
                        }}
                      />
                      <Select
                        disabled={listApplications.isFetching}
                        label="Selecione a aplicação B"
                        variant="outlined"
                        optionKeyField="id"
                        optionTitleField="name"
                        value={Number(accessApplicationParams.secondApp)}
                        options={listApplications.data ?? []}
                        css={{ width: '300px' }}
                        onChange={(option) => {
                          setAccessApplicationParams((oldData) => ({
                            ...oldData,
                            secondApp: String(option?.id)
                          }))
                        }}
                      />
                    </Div>

                    {accessApplicationChangeButton === 0 ? (
                      <Div
                        css={{
                          display: 'flex',
                          flex: 1,
                          gap: '$lg',
                          position: 'relative',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minHeight: 280
                        }}
                      >
                        {firstAccessApplicationUnique.isFetching ? (
                          <>
                            <Loader size={80} />
                          </>
                        ) : (
                          <>
                            <Div
                              css={{
                                width: '100%',
                                padding: '$lg',
                                borderRadius: '$lg',
                                borderWidth: '$thin',
                                borderStyle: 'solid',
                                borderColor: '$outline-variant',
                                position: 'relative'
                              }}
                            >
                              <Div
                                css={{
                                  position: 'absolute',
                                  top: 24,
                                  right: 24,
                                  zIndex: 1
                                }}
                              >
                                <Tooltip label="Representa uma sessão individual de um usuário dentro da plataforma. Geralmente, refere-se ao acesso de um único dispositivo, navegador ou conta ativa em determinado momento.">
                                  <InfoIcon />
                                </Tooltip>
                              </Div>
                              <ReactApexChart
                                options={{
                                  ...defaultLineGraphics.options,
                                  title: {
                                    ...defaultLineGraphics.options.title,
                                    text: 'Acessos únicos'
                                  },
                                  colors: ['#2196F3', '#A631BA'],
                                  xaxis: {
                                    ...defaultLineGraphics.options.xaxis,
                                    categories: firstAccessApplicationUnique.data?.axisDateFormatted
                                  }
                                }}
                                series={[
                                  {
                                    name: firstAccessApplicationUnique.data?.firstApplicationName,
                                    data:
                                      firstAccessApplicationUnique.data
                                        ?.firstSeriesUniqueFormatted ?? []
                                  },
                                  {
                                    name: firstAccessApplicationUnique.data?.secondApplicationName,
                                    data:
                                      firstAccessApplicationUnique.data
                                        ?.secondSeriesUniqueFormatted ?? []
                                  }
                                ]}
                                type="line"
                                width="100%"
                                height={280}
                              />
                            </Div>
                            <Div
                              css={{
                                width: '100%',
                                padding: '$lg',
                                borderRadius: '$lg',
                                borderWidth: '$thin',
                                borderStyle: 'solid',
                                borderColor: '$outline-variant',
                                position: 'relative'
                              }}
                            >
                              <Div
                                css={{
                                  position: 'absolute',
                                  top: 24,
                                  right: 24,
                                  zIndex: 1
                                }}
                              >
                                <Tooltip label="Representa o número total de vezes que uma plataforma foi acessada dentro de um período, podendo incluir múltiplos acessos do mesmo usuário.">
                                  <InfoIcon />
                                </Tooltip>
                              </Div>
                              <ReactApexChart
                                options={{
                                  ...defaultLineGraphics.options,
                                  title: {
                                    ...defaultLineGraphics.options.title,
                                    text: 'Total de acessos'
                                  },
                                  colors: ['#2196F3', '#A631BA'],
                                  xaxis: {
                                    ...defaultLineGraphics.options.xaxis,
                                    categories: firstAccessApplicationUnique.data?.axisDateFormatted
                                  }
                                }}
                                series={[
                                  {
                                    name: firstAccessApplicationUnique.data?.firstApplicationName,
                                    data:
                                      firstAccessApplicationUnique.data
                                        ?.firstSeriesTotalFormatted ?? []
                                  },
                                  {
                                    name: firstAccessApplicationUnique.data?.secondApplicationName,
                                    data:
                                      firstAccessApplicationUnique.data
                                        ?.secondSeriesTotalFormatted ?? []
                                  }
                                ]}
                                type="line"
                                width="100%"
                                height={280}
                              />
                            </Div>
                          </>
                        )}
                      </Div>
                    ) : (
                      <>
                        {firstAccessApplicationUnique.isFetching ? (
                          <Div
                            css={{
                              display: 'flex',
                              flex: 1,
                              position: 'relative',
                              minHeight: 280,
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <Loader size={80} />
                          </Div>
                        ) : (
                          <Div
                            css={{
                              borderRadius: '$md',
                              borderWidth: '$thin',
                              borderStyle: 'solid',
                              borderColor: '$outline-variant'
                            }}
                          >
                            <Div
                              css={{
                                display: 'flex',
                                flex: 1,
                                borderRadius: '$md',
                                flexDirection: 'column',
                                maxHeight: 368,
                                overflow: 'auto'
                              }}
                            >
                              <Grid
                                css={{
                                  display: 'flex',
                                  padding: '$md'
                                }}
                              >
                                <Grid
                                  xl={2.4}
                                  css={{ display: 'flex', alignItems: 'center', gap: '$md' }}
                                >
                                  <Typography
                                    data-testid="Typography-header-discipline"
                                    variant="titleSmall"
                                    lineClamp={1}
                                    css={{ color: '$on-surface' }}
                                  >
                                    Data
                                  </Typography>
                                </Grid>

                                <Grid xl={2.4}>
                                  <Div>
                                    <Typography
                                      data-testid="Typography-header-code"
                                      variant="titleSmall"
                                      lineClamp={1}
                                      css={{ color: '$on-surface' }}
                                    >
                                      Acessos únicos
                                    </Typography>
                                    <Typography
                                      data-testid="Typography-header-code"
                                      variant="labelSmall"
                                      lineClamp={1}
                                      css={{ color: '$on-surface-variant' }}
                                    >
                                      {firstAccessApplicationUnique.data?.firstApplicationName}
                                    </Typography>
                                  </Div>
                                </Grid>
                                <Grid xl={2.4}>
                                  <Div>
                                    <Typography
                                      data-testid="Typography-header-code"
                                      variant="titleSmall"
                                      lineClamp={1}
                                      css={{ color: '$on-surface' }}
                                    >
                                      Acessos únicos
                                    </Typography>
                                    <Typography
                                      data-testid="Typography-header-code"
                                      variant="labelSmall"
                                      lineClamp={1}
                                      css={{ color: '$on-surface-variant' }}
                                    >
                                      {firstAccessApplicationUnique.data?.secondApplicationName}
                                    </Typography>
                                  </Div>
                                </Grid>
                                <Grid xl={2.4}>
                                  <Div>
                                    <Typography
                                      data-testid="Typography-header-code"
                                      variant="titleSmall"
                                      lineClamp={1}
                                      css={{ color: '$on-surface' }}
                                    >
                                      Acessos totais
                                    </Typography>
                                    <Typography
                                      data-testid="Typography-header-code"
                                      variant="labelSmall"
                                      lineClamp={1}
                                      css={{ color: '$on-surface-variant' }}
                                    >
                                      {firstAccessApplicationUnique.data?.firstApplicationName}
                                    </Typography>
                                  </Div>
                                </Grid>
                                <Grid xl={2.4}>
                                  <Div>
                                    <Typography
                                      data-testid="Typography-header-code"
                                      variant="titleSmall"
                                      lineClamp={1}
                                      css={{ color: '$on-surface' }}
                                    >
                                      Acessos totais
                                    </Typography>
                                    <Typography
                                      data-testid="Typography-header-code"
                                      variant="labelSmall"
                                      lineClamp={1}
                                      css={{ color: '$on-surface-variant' }}
                                    >
                                      {firstAccessApplicationUnique.data?.secondApplicationName}
                                    </Typography>
                                  </Div>
                                </Grid>
                              </Grid>

                              <Div
                                css={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  flex: 1,
                                  maxHeight: 368,
                                  overflow: 'auto'
                                }}
                              >
                                {firstAccessApplicationUnique.data?.data.map((application) => (
                                  <Grid
                                    key={application.date}
                                    css={{
                                      display: 'flex',
                                      padding: '$md',
                                      borderWidth: '$thin',
                                      borderStyle: 'solid',
                                      borderColor: '$surface-variant',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <Grid xl={2.4}>
                                      <Typography
                                        data-testid="Typography-header-code"
                                        variant="bodySmall"
                                        lineClamp={1}
                                        css={{ color: '$on-surface-variant' }}
                                      >
                                        {application.date}
                                      </Typography>
                                    </Grid>
                                    <Grid xl={2.4}>
                                      <Typography
                                        data-testid="Typography-header-code"
                                        variant="bodySmall"
                                        lineClamp={1}
                                        css={{ color: '$on-surface-variant' }}
                                      >
                                        {application.apps.firstApp.unique}
                                      </Typography>
                                    </Grid>
                                    <Grid xl={2.4}>
                                      <Typography
                                        data-testid="Typography-header-code"
                                        variant="bodySmall"
                                        lineClamp={1}
                                        css={{ color: '$on-surface-variant' }}
                                      >
                                        {application.apps.secondApp.unique}
                                      </Typography>
                                    </Grid>
                                    <Grid xl={2.4}>
                                      <Typography
                                        data-testid="Typography-header-code"
                                        variant="bodySmall"
                                        lineClamp={1}
                                        css={{ color: '$on-surface-variant' }}
                                      >
                                        {application.apps.firstApp.total}
                                      </Typography>
                                    </Grid>
                                    <Grid xl={2.4}>
                                      <Typography
                                        data-testid="Typography-header-code"
                                        variant="bodySmall"
                                        lineClamp={1}
                                        css={{ color: '$on-surface-variant' }}
                                      >
                                        {application.apps.secondApp.total}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ))}
                              </Div>
                            </Div>
                          </Div>
                        )}
                      </>
                    )}
                  </Div>
                )}
                {listChildren.data?.data && listChildren.data.data.length >= 2 && (
                  <Div
                    css={{
                      flexDirection: 'column',
                      display: 'flex',
                      gap: '$lg',
                      padding: '$lg',
                      borderRadius: '$lg',
                      borderWidth: '$thin',
                      borderStyle: 'solid',
                      borderColor: '$outline-variant'
                    }}
                  >
                    <Div
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant="titleLarge">
                        Comparador de acessos entre escolas
                      </Typography>
                      <ButtonGroup onChangeButton={(pos) => setSchoolAccessChangeButton(pos)} />
                    </Div>
                    <Div css={{ display: 'flex', gap: '$lg', alignItems: 'center' }}>
                      <Select
                        disabled={listApplications.isFetching}
                        label="Selecione a aplicação"
                        variant="outlined"
                        optionKeyField="id"
                        optionTitleField="name"
                        value={Number(firstSchoolAccessApplicationUniqueParams.applicationId)}
                        options={listApplicationsFormatted}
                        css={{ width: '300px' }}
                        onChange={(option) => {
                          setFirstSchoolAccessApplicationUniqueParams((oldData) => ({
                            ...oldData,
                            applicationId: String(option?.id)
                          }))
                        }}
                      />
                      <Select
                        disabled={listApplications.isFetching}
                        label="Selecione a instituição A"
                        variant="outlined"
                        optionKeyField="codHub"
                        optionTitleField="instituicao"
                        value={firstSchoolAccessApplicationUniqueParams.firstInstitutionId}
                        options={listChildren.data.data}
                        css={{ width: '300px' }}
                        onChange={(option) =>
                          setFirstSchoolAccessApplicationUniqueParams((oldData) => ({
                            ...oldData,
                            firstInstitutionId: String(option?.codHub)
                          }))
                        }
                      />
                      <Select
                        disabled={listApplications.isFetching}
                        label="Selecione a intituição B"
                        variant="outlined"
                        optionKeyField="codHub"
                        optionTitleField="instituicao"
                        value={firstSchoolAccessApplicationUniqueParams.secondInstitutionId}
                        options={listChildren.data.data}
                        css={{ width: '300px' }}
                        onChange={(option) =>
                          setFirstSchoolAccessApplicationUniqueParams((oldData) => ({
                            ...oldData,
                            secondInstitutionId: String(option?.codHub)
                          }))
                        }
                      />
                    </Div>

                    {schoolAccessChangeButton === 0 ? (
                      <Div
                        css={{
                          display: 'flex',
                          flex: 1,
                          gap: '$lg',
                          position: 'relative',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minHeight: 280
                        }}
                      >
                        {listAccessSchool.isFetching ? (
                          <>
                            <Loader size={80} />
                          </>
                        ) : (
                          <Div
                            css={{
                              width: '100%',
                              position: 'relative'
                              // padding: '$lg'
                              // borderRadius: '$lg',
                              // borderWidth: '$thin',
                              // borderStyle: 'solid',
                              // borderColor: '$outline-variant'
                            }}
                          >
                            <Div
                              css={{
                                position: 'absolute',
                                top: 0,
                                right: 24,
                                zIndex: 1
                              }}
                            >
                              <Tooltip label="Representa a comparação dos acessos únicos de uma sessão individual de um usuário dentro da plataforma entre duas instituições. Geralmente, refere-se ao acesso de um único dispositivo, navegador ou conta ativa em determinado momento.">
                                <InfoIcon />
                              </Tooltip>
                            </Div>
                            <ReactApexChart
                              options={{
                                ...defaultLineGraphics.options,
                                title: {
                                  ...defaultLineGraphics.options.title
                                },
                                colors: ['#8BC34A', '#FF5722'],
                                xaxis: {
                                  ...defaultLineGraphics.options.xaxis,
                                  categories: listAccessSchool.data?.axisDateFormatted
                                }
                              }}
                              series={[
                                {
                                  name: listAccessSchool.data?.firstInstitutionName,
                                  data: listAccessSchool.data?.firstSeriesUniqueFormatted ?? []
                                },
                                {
                                  name: listAccessSchool.data?.secondInstitutionName,
                                  data: listAccessSchool.data?.secondSeriesUniqueFormatted ?? []
                                }
                              ]}
                              type="line"
                              width="100%"
                              height={280}
                            />
                          </Div>
                        )}
                      </Div>
                    ) : (
                      <>
                        {listAccessSchool.isFetching ? (
                          <Div
                            css={{
                              display: 'flex',
                              flex: 1,
                              position: 'relative',
                              minHeight: 280,
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <Loader size={80} />
                          </Div>
                        ) : (
                          <Div
                            css={{
                              borderRadius: '$md',
                              borderWidth: '$thin',
                              borderStyle: 'solid',
                              borderColor: '$outline-variant'
                            }}
                          >
                            <Div
                              css={{
                                display: 'flex',
                                flex: 1,
                                borderRadius: '$md',
                                flexDirection: 'column',
                                maxHeight: 368,
                                overflow: 'auto'
                              }}
                            >
                              <Grid
                                css={{
                                  display: 'flex',
                                  padding: '$md'
                                }}
                              >
                                <Grid
                                  xl={4}
                                  css={{ display: 'flex', alignItems: 'center', gap: '$md' }}
                                >
                                  <Typography
                                    data-testid="Typography-header-discipline"
                                    variant="titleSmall"
                                    lineClamp={1}
                                    css={{ color: '$on-surface' }}
                                  >
                                    Data
                                  </Typography>
                                </Grid>

                                <Grid xl={4}>
                                  <Div>
                                    <Typography
                                      data-testid="Typography-header-code"
                                      variant="titleSmall"
                                      lineClamp={1}
                                      css={{ color: '$on-surface' }}
                                    >
                                      Acessos únicos
                                    </Typography>
                                    <Typography
                                      data-testid="Typography-header-code"
                                      variant="labelSmall"
                                      lineClamp={1}
                                      css={{ color: '$on-surface-variant' }}
                                    >
                                      {listAccessSchool.data?.firstInstitutionName}
                                    </Typography>
                                  </Div>
                                </Grid>
                                <Grid xl={4}>
                                  <Div>
                                    <Typography
                                      data-testid="Typography-header-code"
                                      variant="titleSmall"
                                      lineClamp={1}
                                      css={{ color: '$on-surface' }}
                                    >
                                      Acessos únicos
                                    </Typography>
                                    <Typography
                                      data-testid="Typography-header-code"
                                      variant="labelSmall"
                                      lineClamp={1}
                                      css={{ color: '$on-surface-variant' }}
                                    >
                                      {listAccessSchool.data?.secondInstitutionName}
                                    </Typography>
                                  </Div>
                                </Grid>
                              </Grid>

                              <Div
                                css={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  flex: 1,
                                  maxHeight: 368,
                                  overflow: 'auto'
                                }}
                              >
                                {listAccessSchool.data?.data.map((application) => (
                                  <Grid
                                    key={application.date}
                                    css={{
                                      display: 'flex',
                                      padding: '$md',
                                      borderWidth: '$thin',
                                      borderStyle: 'solid',
                                      borderColor: '$surface-variant',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <Grid xl={4}>
                                      <Typography
                                        data-testid="Typography-header-code"
                                        variant="bodySmall"
                                        lineClamp={1}
                                        css={{ color: '$on-surface-variant' }}
                                      >
                                        {application.date}
                                      </Typography>
                                    </Grid>
                                    <Grid xl={4}>
                                      <Typography
                                        data-testid="Typography-header-code"
                                        variant="bodySmall"
                                        lineClamp={1}
                                        css={{ color: '$on-surface-variant' }}
                                      >
                                        {application.orgs.firstInstitution.unique}
                                      </Typography>
                                    </Grid>
                                    <Grid xl={4}>
                                      <Typography
                                        data-testid="Typography-header-code"
                                        variant="bodySmall"
                                        lineClamp={1}
                                        css={{ color: '$on-surface-variant' }}
                                      >
                                        {application.orgs.secondInstitution.unique}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ))}
                              </Div>
                            </Div>
                          </Div>
                        )}
                      </>
                    )}
                  </Div>
                )}

                {listChildren.data?.data && listChildren.data.data.length >= 2 && (
                  <Div
                    css={{
                      flexDirection: 'column',
                      display: 'flex',
                      gap: '$lg',
                      padding: '$lg',
                      borderRadius: '$lg',
                      borderWidth: '$thin',
                      borderStyle: 'solid',
                      borderColor: '$outline-variant'
                    }}
                  >
                    <Div
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant="titleLarge">Acessos únicos por escolas</Typography>
                      <ButtonGroup
                        onChangeButton={(pos) => setUniqueAccessesSchoolsChangeButton(pos)}
                      />
                    </Div>

                    {uniqueAccessesSchoolsChangeButton === 0 ? (
                      <Div
                        css={{
                          display: 'flex',
                          flex: 1,
                          gap: '$lg',
                          position: 'relative',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minHeight: 280,
                          marginTop: '-25px'
                        }}
                      >
                        <Div
                          css={{
                            width: '100%'
                          }}
                        >
                          <ReactApexChart
                            options={{
                              ...defaultSimpleBarGraphics.options,
                              colors: ['#2196F3'],
                              xaxis: {
                                ...defaultSimpleBarGraphics.options.xaxis,
                                categories:
                                  listAccessApplicationSchoolRoles.data?.xaxisTotalFormatted ?? []
                              }
                            }}
                            series={[
                              {
                                name: 'Total de acessos',
                                data:
                                  listAccessApplicationSchoolRoles.data?.seriesDateFormatted ?? []
                              }
                            ]}
                            type="bar"
                            width="100%"
                            height={450}
                          />
                        </Div>
                      </Div>
                    ) : (
                      <Div
                        css={{
                          borderRadius: '$md',
                          borderWidth: '$thin',
                          borderStyle: 'solid',
                          borderColor: '$outline-variant'
                        }}
                      >
                        <Div
                          css={{
                            display: 'flex',
                            flex: 1,
                            borderRadius: '$md',
                            flexDirection: 'column',
                            maxHeight: 368,
                            overflow: 'auto'
                          }}
                        >
                          <Grid
                            css={{
                              display: 'flex',
                              padding: '$md'
                            }}
                          >
                            <Grid
                              xl={2.4}
                              css={{ display: 'flex', alignItems: 'center', gap: '$md' }}
                            >
                              <Typography
                                data-testid="Typography-header-discipline"
                                variant="titleSmall"
                                lineClamp={1}
                                css={{ color: '$on-surface' }}
                              >
                                Escolas
                              </Typography>
                            </Grid>

                            <Grid xl={2.4}>
                              <Typography
                                data-testid="Typography-header-code"
                                variant="titleSmall"
                                lineClamp={1}
                                css={{ color: '$on-surface' }}
                              >
                                Total de acessos
                              </Typography>
                            </Grid>
                            <Grid xl={2.4}>
                              <Typography
                                data-testid="Typography-header-base-discipline"
                                variant="titleSmall"
                                lineClamp={1}
                                css={{ color: '$on-surface' }}
                              >
                                Estudantes
                              </Typography>
                            </Grid>
                            <Grid xl={2.4}>
                              <Typography
                                data-testid="Typography-header-base-discipline"
                                variant="titleSmall"
                                lineClamp={1}
                                css={{ color: '$on-surface' }}
                              >
                                Professores
                              </Typography>
                            </Grid>
                            <Grid xl={2.4}>
                              <Typography
                                data-testid="Typography-header-base-discipline"
                                variant="titleSmall"
                                lineClamp={1}
                                css={{ color: '$on-surface' }}
                              >
                                Colaboradores
                              </Typography>
                            </Grid>
                          </Grid>

                          <Div
                            css={{
                              display: 'flex',
                              flexDirection: 'column',
                              flex: 1,
                              maxHeight: 368,
                              overflow: 'auto'
                            }}
                          >
                            {listAccessApplicationSchoolRoles.data?.data.map((application) => (
                              <Grid
                                key={application.name}
                                css={{
                                  display: 'flex',
                                  padding: '$md',
                                  borderWidth: '$thin',
                                  borderStyle: 'solid',
                                  borderColor: '$surface-variant',
                                  alignItems: 'center'
                                }}
                              >
                                <Grid
                                  xl={2.4}
                                  css={{ display: 'flex', alignItems: 'center', gap: '$md' }}
                                >
                                  <Div css={{ display: 'flex', gap: '$sm', alignItems: 'center' }}>
                                    <IconWrapper
                                      size={24}
                                      css={{ backgroundColor: '$primary-container' }}
                                    >
                                      <SchoolOrgIcon size={14} fill="$primary" />
                                    </IconWrapper>
                                    <Typography
                                      variant="bodySmall"
                                      css={{ color: '$on-surface-variant' }}
                                    >
                                      {application.name}
                                    </Typography>
                                  </Div>
                                </Grid>

                                <Grid xl={2.4}>
                                  <Typography
                                    data-testid="Typography-header-code"
                                    variant="bodySmall"
                                    lineClamp={1}
                                    css={{ color: '$on-surface-variant' }}
                                  >
                                    {application.total}
                                  </Typography>
                                </Grid>
                                <Grid xl={2.4}>
                                  <Typography
                                    data-testid="Typography-header-code"
                                    variant="bodySmall"
                                    lineClamp={1}
                                    css={{ color: '$on-surface-variant' }}
                                  >
                                    {application.students}
                                  </Typography>
                                </Grid>
                                <Grid xl={2.4}>
                                  <Typography
                                    data-testid="Typography-header-code"
                                    variant="bodySmall"
                                    lineClamp={1}
                                    css={{ color: '$on-surface-variant' }}
                                  >
                                    {application.teathers}
                                  </Typography>
                                </Grid>
                                <Grid xl={2.4}>
                                  <Typography
                                    data-testid="Typography-header-code"
                                    variant="bodySmall"
                                    lineClamp={1}
                                    css={{ color: '$on-surface-variant' }}
                                  >
                                    {application.staff}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))}
                          </Div>
                        </Div>
                      </Div>
                    )}
                  </Div>
                )}

                <Div
                  css={{
                    flexDirection: 'column',
                    display: 'flex',
                    gap: '$lg',
                    padding: '$lg',
                    borderRadius: '$lg',
                    borderWidth: '$thin',
                    borderStyle: 'solid',
                    borderColor: '$outline-variant'
                  }}
                >
                  <Div
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography variant="titleLarge">Acessos únicos por aplicações</Typography>
                    <ButtonGroup
                      onChangeButton={(pos) => setSingleAccessApplicationChangeButton(pos)}
                    />
                  </Div>

                  {singleAccessApplicationChangeButton === 0 ? (
                    <Div
                      css={{
                        display: 'flex',
                        flex: 1,
                        gap: '$lg',
                        position: 'relative',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: 280,
                        marginTop: '-25px'
                      }}
                    >
                      {listAccessApplicationUniqueRoles.isFetching ? (
                        <>
                          <Loader size={80} />
                        </>
                      ) : (
                        <Div
                          css={{
                            width: '100%',
                            position: 'relative'
                          }}
                        >
                          <ReactApexChart
                            options={{
                              ...defaultBarGraphics.options,
                              title: {
                                ...defaultBarGraphics.options.title
                              },
                              colors: ['#2196F3', '#A631BA', '#FFC107'],
                              xaxis: {
                                ...defaultBarGraphics.options.xaxis,
                                categories:
                                  listAccessApplicationUniqueRoles.data?.xaxisTotalFormatted
                              }
                            }}
                            series={listAccessApplicationUniqueRoles.data?.seriesDateFormatted}
                            type="bar"
                            width="100%"
                            height={280}
                          />
                        </Div>
                      )}
                    </Div>
                  ) : (
                    <Div
                      css={{
                        borderRadius: '$md',
                        borderWidth: '$thin',
                        borderStyle: 'solid',
                        borderColor: '$outline-variant'
                      }}
                    >
                      <Div
                        css={{
                          display: 'flex',
                          flex: 1,
                          borderRadius: '$md',
                          flexDirection: 'column',
                          maxHeight: 368,
                          overflow: 'auto'
                        }}
                      >
                        <Grid
                          css={{
                            display: 'flex',
                            padding: '$md'
                          }}
                        >
                          <Grid
                            xl={2.4}
                            css={{ display: 'flex', alignItems: 'center', gap: '$md' }}
                          >
                            <Typography
                              data-testid="Typography-header-discipline"
                              variant="titleSmall"
                              lineClamp={1}
                              css={{ color: '$on-surface' }}
                            >
                              Aplicações
                            </Typography>
                          </Grid>

                          <Grid xl={2.4}>
                            <Typography
                              data-testid="Typography-header-code"
                              variant="titleSmall"
                              lineClamp={1}
                              css={{ color: '$on-surface' }}
                            >
                              Total de acessos
                            </Typography>
                          </Grid>
                          <Grid xl={2.4}>
                            <Typography
                              data-testid="Typography-header-base-discipline"
                              variant="titleSmall"
                              lineClamp={1}
                              css={{ color: '$on-surface' }}
                            >
                              Estudantes
                            </Typography>
                          </Grid>
                          <Grid xl={2.4}>
                            <Typography
                              data-testid="Typography-header-base-discipline"
                              variant="titleSmall"
                              lineClamp={1}
                              css={{ color: '$on-surface' }}
                            >
                              Professores
                            </Typography>
                          </Grid>
                          <Grid xl={2.4}>
                            <Typography
                              data-testid="Typography-header-base-discipline"
                              variant="titleSmall"
                              lineClamp={1}
                              css={{ color: '$on-surface' }}
                            >
                              Colaboradores
                            </Typography>
                          </Grid>
                        </Grid>

                        <Div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            maxHeight: 368,
                            overflow: 'auto'
                          }}
                        >
                          {listAccessApplicationUniqueRoles.data?.data.map((application) => (
                            <Grid
                              key={application.name}
                              css={{
                                display: 'flex',
                                padding: '$md',
                                borderWidth: '$thin',
                                borderStyle: 'solid',
                                borderColor: '$surface-variant',
                                alignItems: 'center'
                              }}
                            >
                              <Grid
                                xl={2.4}
                                css={{ display: 'flex', alignItems: 'center', gap: '$md' }}
                              >
                                <Div css={{ display: 'flex', gap: '$sm', alignItems: 'center' }}>
                                  <Image
                                    alt="2"
                                    height={24}
                                    width={24}
                                    src={application.icone}
                                    css={{
                                      borderRadius: '$full',
                                      borderWidth: '$thin',
                                      borderStyle: 'solid',
                                      borderColor: '$outline-variant'
                                    }}
                                  />
                                  <Typography
                                    variant="bodySmall"
                                    css={{ color: '$on-surface-variant' }}
                                  >
                                    {application.name}
                                  </Typography>
                                </Div>
                              </Grid>

                              <Grid xl={2.4}>
                                <Typography
                                  data-testid="Typography-header-code"
                                  variant="bodySmall"
                                  lineClamp={1}
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  {application.total}
                                </Typography>
                              </Grid>
                              <Grid xl={2.4}>
                                <Typography
                                  data-testid="Typography-header-code"
                                  variant="bodySmall"
                                  lineClamp={1}
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  {application.students}
                                </Typography>
                              </Grid>
                              <Grid xl={2.4}>
                                <Typography
                                  data-testid="Typography-header-code"
                                  variant="bodySmall"
                                  lineClamp={1}
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  {application.teachers}
                                </Typography>
                              </Grid>
                              <Grid xl={2.4}>
                                <Typography
                                  data-testid="Typography-header-code"
                                  variant="bodySmall"
                                  lineClamp={1}
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  {application.staff}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Div>
                      </Div>
                    </Div>
                  )}
                </Div>
              </>
            )}
          </>
        )}
      </Div>
    </Main>
  )
}
