import { Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function InterestsIcon({ fill, size = '$lg', css, ...restProps }: IconProps): JSX.Element {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      css={{
        fill: 'none',
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        ...css
      }}
      {...restProps}
    >
      <Path
        d="M7.02 13.5C4.81 13.5 3.02 15.29 3.02 17.5C3.02 19.71 4.81 21.5 7.02 21.5C9.23 21.5 11.02 19.71 11.02 17.5C11.02 15.29 9.23 13.5 7.02 13.5ZM13 13.5V21.5H21V13.5H13ZM7 2.5L2 11.5H12L7 2.5ZM19.25 3C18.19 3 17.44 3.56 17 4.17C16.56 3.56 15.81 3 14.75 3C13.19 3 12 4.28 12 5.75C12 7.75 14.42 9.17 17 11.5C19.58 9.17 22 7.75 22 5.75C22 4.28 20.81 3 19.25 3Z"
        css={{ fill }}
      />
    </Svg>
  )
}
