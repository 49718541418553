import { Div } from '@positivote/design-system/components/Div'
import { Iframe } from '@positivote/design-system/components/Iframe'
import { Image } from '@positivote/design-system/components/Image'
import { Main } from '@positivote/design-system/components/Main'
import { Typography } from '@positivote/design-system/components/Typography'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { useListBannerJson } from '@/common/components/Banner/hook'
import { WrappedLoader } from '@/common/components/WrappedLoader'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'

export function ListEducationalContents(): JSX.Element {
  changePageTitle(i18n().modules.hub.educationalContents.pages.pageTitle)

  const navigate = useNavigate()
  const { profile } = useAuth()
  const location = useLocation()
  const locationState = location.state as {
    embedded?: string
  } | null
  const [embedded, setEmbedded] = useState(locationState?.embedded)

  const baseJson = useListBannerJson({
    queryOptions: {
      staleTime: 0
    }
  })

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        title={i18n().modules.hub.educationalContents.pages.appBar.title}
        goBackFunction={() => {
          if (embedded) {
            setEmbedded('')
          } else {
            navigate(-1)
          }
        }}
      />
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '$lg',
          overflowY: 'auto',
          '@sm': { padding: '$md' }
        }}
      >
        {!profile ? (
          <WrappedLoader />
        ) : embedded ? (
          <Iframe src={embedded} css={{ width: '100%', height: '100%' }} />
        ) : (
          <Div css={{ display: 'flex', gap: '$lg', flexWrap: 'wrap' }}>
            {baseJson.data?.map((banner) => (
              <Div
                onClick={() =>
                  banner.cta.type === 'OPEN_EMBEDED'
                    ? setEmbedded(banner.cta.url)
                    : window.open(banner.cta.url, '_blank')
                }
                key={banner.order}
                css={{
                  display: 'flex',
                  maxWidth: '320px',
                  borderWidth: '$thin',
                  borderStyle: 'solid',
                  borderColor: '$outline-variant',
                  flexDirection: 'column',
                  cursor: 'pointer'
                }}
              >
                <Image
                  alt={banner.description}
                  width="100%"
                  height={200}
                  src={banner.backgroud}
                  css={{ objectFit: 'cover' }}
                />
                <Div css={{ display: 'flex', background: '$surface-2', padding: '$md', flex: 1 }}>
                  <Typography lineClamp={2} variant="titleMedium">
                    {banner.title}
                  </Typography>
                </Div>
              </Div>
            ))}
          </Div>
        )}
      </Div>
    </Main>
  )
}
