import { Navigate, Route, Routes } from 'react-router-dom'

import { ListEducationalContents } from './pages'

export function EducationalContentsRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/educational-contents" element={<ListEducationalContents />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
