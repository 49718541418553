export const commonPt = {
  resources: {
    listJsonBanner: 'Listar JSON Banner'
  },
  loading: 'Carregando',
  abbreviatedMonths: {
    january: 'jan',
    february: 'fev',
    march: 'mar',
    april: 'abr',
    may: 'mai',
    june: 'jun',
    july: 'jul',
    august: 'ago',
    september: 'set',
    october: 'out',
    november: 'nov',
    december: 'dez'
  },
  months: {
    january: 'Janeiro',
    february: 'Fevereiro',
    march: 'Março',
    april: 'Abril',
    may: 'Maio',
    june: 'Junho',
    july: 'Julho',
    august: 'Agosto',
    september: 'Setembro',
    october: 'Outubro',
    november: 'Novembro',
    december: 'Dezembro'
  },
  components: {
    appBar: {
      loginSupport: 'Login Suporte'
    },
    banner: {
      hello: 'Olá, ',
      welcome: ' Seja bem-vindo(a) ao Hub Educacional!',
      AccessContent: 'Acessar conteúdo'
    },
    largerScreensOnly: {
      title: 'Você pode apenas visualizar em telas maiores',
      subTitle: 'Este recurso não está disponível nesta resolução de tela.'
    },
    drawer: {
      options: {
        home: 'Inicial',
        reports: 'Relatórios',
        dashboard: 'Dashboard',
        mySolutions: 'Minhas soluções',
        dataIntelligence: 'Inteligência de dados',
        dataAnalysis: 'Análise de dados',
        monitoora: 'Monitoora',
        credentials: 'Credenciais',
        quickAccess: 'Acesso rápido',
        accessReports: 'Relatórios de acessos',
        settings: 'Configurações',
        dataManagement: 'Gerenciamento de dados',
        logout: 'Sair',
        menu: 'Menu'
      },
      signOutDialog: {
        title: 'Sair da plataforma?',
        content: 'Tem certeza que deseja sair do Hub Educacional?',
        refuse: 'Não',
        accept: 'Sim'
      }
    },
    emptySearch: {
      title: 'Não há resultados para esta busca!'
    },
    table: {
      body: {
        attention: 'Atenção!',
        confirm: 'Sim',
        cancel: 'Não'
      },
      head: {
        actions: 'Ações'
      },
      header: {
        selectAField: 'Selecione um campo',
        atField: 'No campo:',
        searchFor: 'Buscar por:'
      }
    },
    supportLoginBar: {
      currentUser: (name?: string, role?: string): string =>
        `Você está logado como ${name} - ${role}`,
      exitSupport: 'Sair do login suporte'
    },
    exitConfirmDialog: {
      title: 'Deseja sair do cadastro?',
      contentTexts:
        'Para gravar os cadastros, será necessário clicar no botão ”Finalizar cadastro”, disponível na última etapa. Caso contrário, os dados preenchidos serão perdidos.',
      refuseActionTitle: 'Voltar para o cadastro',
      acceptActionTitle: 'Sair do cadastro'
    }
  },
  exceptions: {
    applicationException: 'Ocorreu um erro inesperado',
    forbiddenException: (resource: string): string =>
      `Sem permissão para acessar o recurso: ${resource}`,
    notFound: 'Registro não encontrado',
    unauthorizedException: 'Credênciais inválidas ou sessão expirada',
    unprocessableEntityException: 'Occorreu uma falha na regra de negócio',
    validationException: 'Ocorreu um erro de validação'
  },
  helpers: {
    gender: {
      female: 'Feminino',
      male: 'Masculino',
      other: 'Outro'
    }
  },
  layouts: {
    baseBackground: {
      footer: {
        hub: 'Hub Educacional',
        copyright: '© Positivo Tecnologia S.A. Todos os direitos reservados.',
        help: 'Ajuda',
        terms: 'Termos de Uso | Privacidade',
        systemRequirementsChecker: 'Verificador de requisitos do sistema'
      }
    }
  },
  pages: {
    notFound: {
      title: 'Página não encontrada',
      message: 'Página não encontrada',
      verifyTypedAddress: 'Verifique o endereço digitado e tente novamente.',
      buttons: {
        goBack: 'Voltar ao início'
      }
    },
    designSystem: {
      title: 'Design System'
    }
  },
  file: {
    base64PNGToPDFError: 'Erro ao converter base64 para PDF:'
  },
  services: {
    hubApi: {
      makeRefreshToken: 'Atualizar token da sessão do hub'
    }
  },
  hooks: {
    countdown: {
      minutes: 'minutos'
    }
  },
  validators: {
    oneOf: (values: Array<number | string>): string =>
      `Selecione algum dos valores: ${values.join(', ')}`,
    max: (value: number): string => `Este campo deve conter no máximo ${value} caracteres.`,
    min: (value: number): string => `Este campo deve conter ${value} ou mais caracteres.`,
    maxValue: (value: number | string): string =>
      `Este valor deve ser menor ou igual a(o) ${value}`,
    minValue: (value: number | string): string =>
      `Este valor deve ser maior ou igual a(o) ${value}`,
    required: 'Este campo é obrigatório',
    url: 'Este campo deve ser uma URL válida',
    isDateSmallerToNow: 'É necessário inserir uma data válida.',
    cpfInvalid: 'CPF inválido',
    phoneInvalid: 'É necessário inserir um telefone válido.',
    emailInvalid: 'E-mail inválido',
    emailNotFound: 'Endereço de e-mail não encontrado',
    codeInvalid: 'Código inválido',
    minPasswordInvalid: 'A senha deve ter no mínimo 8 caracteres.',
    passwordNotSpecialCharacter: 'A senha deve conter pelo menos 1 caracter especial.',
    passwordNotLetter: 'A senha deve conter pelo menos 1 letra.',
    passwordComplexity: 'A senha deve conter letras maiúscula, minúsculas e números.',
    passwordNotNumber: 'A senha deve conter pelo menos 1 número.',
    passwordEqualToUsername: 'A senha não poderá ser igual ao seu login.',
    passwordEqualToTempPassword: 'Digite uma senha diferente da temporária.',
    passwordNotSequentialCharacters: 'A senha não poderá ter caracteres sequenciais.',
    notEqualsPasswordInvalid: 'As senhas não combinam',
    validateDateRange: 'É necessário inserir uma data posterior a data de início.',
    validateLaterDate: 'É necessário inserir uma data anterior à data atual',
    invalidType: (name: string): string => `${name} formato inválido.`,
    invalidSize: (maxFileSize: number): string =>
      `É necessário inserir um arquivo de no maximo ${maxFileSize / 1000}MB`
  }
}
