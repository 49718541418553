import { i18n } from '@/common/i18n'
import { removeLastActionAtRepository } from '@/common/repositories'
import {
  getHubApiClientToken,
  hubApiService,
  removeHubApiClientSession,
  setHubApiClientSession,
  setHubApiClientToken
} from '@/common/services'
import { fromMeAccountApiSanitizer } from '@/modules/hub/accounts/sanitizers'
import {
  ChangePasswordApiParams,
  ChangePasswordApiResult,
  CreateSessionApiParams,
  CreateSessionApiResult,
  RecoverPasswordApiParams,
  RecoverPasswordApiResult,
  ResetPasswordApiParams,
  ResetPasswordApiResult,
  ShowUserMeApiResult
} from '@/modules/hub/auth/contracts/apis'
import { Session, Token } from '@/modules/hub/auth/contracts/models'
import {
  AuthFlow,
  ChangePasswordServiceParams,
  ChangePasswordServiceResult,
  CreateSessionServiceParams,
  CreateSessionServiceResult,
  RecoverPasswordServiceParams,
  RecoverPasswordServiceResult,
  ResetPasswordServiceParams,
  ShowSessionServiceResult
} from '@/modules/hub/auth/contracts/services'
import {
  getSessionRepository,
  getSessionSupportRepository,
  getTokenRepository,
  getTokenSessionRepository,
  getTokenSupportRepository,
  removeSessionRepository,
  removeSessionSupportRepository,
  removeTokenSessionRepository,
  saveSessionRepository,
  saveTokenRepository,
  saveTokenSessionRepository
} from '@/modules/hub/auth/repositories'
import {
  fromPermissionMeApiSanitizer,
  resetPasswordApiParamsSanitizer
} from '@/modules/hub/auth/sanitizers'
import { fromApiMeProfileSanitizer } from '@/modules/hub/profiles/sanitizers'
import { listTermSanitizer } from '@/modules/hub/terms/sanitizers'
import { fromShowUserMeApiSanitizer } from '@/modules/hub/white-label/sanitizers'

export async function createSessionService<CurrentAuthFlow extends AuthFlow>(
  params: CreateSessionServiceParams<CurrentAuthFlow>
): Promise<CreateSessionServiceResult> {
  const body = { AuthFlow: params.authFlow } as CreateSessionApiParams<CurrentAuthFlow>

  if (params.authFlow === 'EMAIL' && body.AuthFlow === 'EMAIL') {
    body.AuthParameters = {
      USERNAME: params.username,
      PASSWORD: params.password
    }
  }
  if (params.authFlow === 'ESCOLA' && body.AuthFlow === 'ESCOLA') {
    body.AuthParameters = {
      USERNAME: params.username,
      PASSWORD: params.password,
      INSTITUTION: params.orgId
    }
  }
  if (params.authFlow === 'QRCODE' && body.AuthFlow === 'QRCODE') {
    body.AuthParameters = {
      CODE: params.code
    }
  }
  if (params.authFlow === 'QUICK_ACCESS' && body.AuthFlow === 'QUICK_ACCESS') {
    body.AuthParameters = {
      USERNAME: params.username,
      CODE: params.code.toUpperCase()
    }
  }
  if (params.authFlow === 'SOCIAL' && body.AuthFlow === 'SOCIAL') {
    body.AuthParameters = {
      USERNAME: params.username,
      ITK: params.itk,
      TYPE: params.type
    }
  }
  if (params.authFlow === 'SUPORTE' && body.AuthFlow === 'SUPORTE') {
    body.AuthParameters = {
      USERNAME: params.username,
      ACCOUNT_PERSONIFICATION_ID: params.accountPersonificationId,
      INSTITUTION: params.institution
    }
  }

  const { data } = await hubApiService<
    CreateSessionApiParams<CurrentAuthFlow>,
    CreateSessionApiResult
  >({
    resource: i18n().modules.hub.auth.resources.createSession,
    method: 'post',
    url: `/login/v1/auth${params.authFlow === 'SUPORTE' ? '/support' : ''}`,
    body
  })

  const token = {
    tokenType: data.TokenType,
    accessToken: data.AccessToken,
    idToken: data.IdToken,
    refreshToken: data.RefreshToken,
    expiresIn: data.ExpiresIn
  }

  setHubApiClientToken(token)
  saveTokenSessionRepository(token)

  return { token }
}

export async function showSessionService(): Promise<ShowSessionServiceResult> {
  const { data } = await hubApiService<null, ShowUserMeApiResult>({
    resource: i18n().modules.hub.auth.resources.showSession,
    method: 'get',
    url: '/users/v1/users/me',
    ignoreRefreshing: true
  })

  const session = {
    schoolId: data.profile.institution.code,
    orgId: data.profile.institution.id,
    userId: data.profile.user_id,
    profileId: data.profile.id,
    role: data.profile.role.code,
    schoolName: data.profile.institution.name
  }

  saveSessionService(session)
  const token = getHubApiClientToken()
  if (token) {
    saveTokenService(token)
  }

  return {
    session,
    account: fromMeAccountApiSanitizer(data.account),
    profile: fromApiMeProfileSanitizer(data.profile),
    permissions: fromPermissionMeApiSanitizer(data.account_permissions),
    terms: listTermSanitizer(data.terms),
    whiteLabel: data.theme && fromShowUserMeApiSanitizer(data)
  }
}

export async function recoverPasswordService({
  email
}: RecoverPasswordServiceParams): Promise<RecoverPasswordServiceResult> {
  const { data } = await hubApiService<RecoverPasswordApiParams, RecoverPasswordApiResult>({
    resource: i18n().modules.hub.auth.resources.recoverPassword,
    method: 'post',
    url: '/login/v1/recovery/forgotpassword',
    body: {
      username: email
    }
  })
  return data
}

export async function changePasswordService({
  code,
  email,
  newPassword
}: ChangePasswordServiceParams): Promise<ChangePasswordServiceResult> {
  const { data } = await hubApiService<ChangePasswordApiParams, ChangePasswordApiResult>({
    resource: i18n().modules.hub.auth.resources.changePassword,
    method: 'post',
    url: '/login/v1/recovery/confirmforgotpassword',
    body: {
      confirmationCode: code,
      password: newPassword,
      username: email
    }
  })
  return data
}

export async function resetPasswordService(params: ResetPasswordServiceParams): Promise<void> {
  await hubApiService<ResetPasswordApiParams, ResetPasswordApiResult>({
    resource: i18n().modules.hub.auth.resources.changePassword,
    method: 'post',
    url: '/login/v1/auth',
    body: resetPasswordApiParamsSanitizer(params)
  })
}

export function loadHubApiClientService(): {
  session: Session
  token: Token
  supportSession: Session | null
  supportToken: Token | null
} | null {
  const token = getTokenRepository() ?? getTokenSessionRepository()
  const session = getSessionRepository()
  if (token) {
    setHubApiClientToken(token)
    if (session) {
      setHubApiClientSession(session)
      return {
        session,
        token,
        supportSession: getSessionSupportRepository(),
        supportToken: getTokenSupportRepository()
      }
    }
  }
  return null
}

export function saveSessionService(params: Session): void {
  setHubApiClientSession(params)
  saveSessionRepository(params)
}

export function saveTokenService(params: Token): void {
  setHubApiClientToken(params)
  saveTokenRepository(params)
}

export function logoutSessionService(): void {
  removeHubApiClientSession()
  removeSessionRepository()
  removeSessionSupportRepository()
  removeLastActionAtRepository()
  removeTokenSessionRepository()
}

export function logoutSessionSupportService(): void {
  const session = getSessionSupportRepository()
  const token = getTokenSupportRepository()
  if (session && token) {
    saveSessionService(session)
    saveTokenService(token)
  }
  removeSessionSupportRepository()
}
