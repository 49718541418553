import { i18n } from '@/common/i18n'

import { ClassLevelCode } from './contracts'
import { splitClassLevel } from './helpers'

export const CLASS_LEVEL_OPTIONS: Array<{
  key: keyof typeof ClassLevelCode
  value: string
  finalValue: string
  fullValue: string
}> = Object.keys(ClassLevelCode).map((key) => {
  const parsedKey = key as keyof typeof ClassLevelCode
  const value = i18n().modules.hub.classroom.abbreviatedSegmentClassLevel[parsedKey]
  const fullValue = i18n().modules.hub.classroom.fullClassLevel[parsedKey]
  const { level } = splitClassLevel(value)
  return { key: parsedKey, value, finalValue: level, fullValue }
})

export const CLASS_LEVEL_PER_SEGMENT = [
  {
    key: 'EI',
    value: i18n().modules.hub.classroom.abbreviatedSegmentClassLevel.EI,
    classLevels: CLASS_LEVEL_OPTIONS.filter(
      (classLevelOption) =>
        classLevelOption.key === 'CHILD_EDUCATION' || classLevelOption.value.includes('EI -')
    )
  },
  {
    key: 'EI_ESP',
    value: i18n().modules.hub.classroom.abbreviatedSegmentClassLevel.EI_ESP,
    classLevels: CLASS_LEVEL_OPTIONS.filter((classLevelOption) =>
      classLevelOption.value.includes('EI Especial -')
    )
  },
  {
    key: 'EF1',
    value: i18n().modules.hub.classroom.abbreviatedSegmentClassLevel.EF1,
    classLevels: CLASS_LEVEL_OPTIONS.filter((classLevelOption) =>
      classLevelOption.value.includes('EF1 -')
    )
  },
  {
    key: 'EF2',
    value: i18n().modules.hub.classroom.abbreviatedSegmentClassLevel.EF2,
    classLevels: CLASS_LEVEL_OPTIONS.filter((classLevelOption) =>
      classLevelOption.value.includes('EF2 -')
    )
  },
  {
    key: 'EF1_ESP',
    value: i18n().modules.hub.classroom.abbreviatedSegmentClassLevel.EF1_ESP,
    classLevels: CLASS_LEVEL_OPTIONS.filter((classLevelOption) =>
      classLevelOption.value.includes('EF1 Especial -')
    )
  },
  {
    key: 'EF2_ESP',
    value: i18n().modules.hub.classroom.abbreviatedSegmentClassLevel.EF2_ESP,
    classLevels: CLASS_LEVEL_OPTIONS.filter((classLevelOption) =>
      classLevelOption.value.includes('EF2 Especial -')
    )
  },
  {
    key: 'EM',
    value: i18n().modules.hub.classroom.abbreviatedSegmentClassLevel.EM,
    classLevels: CLASS_LEVEL_OPTIONS.filter((classLevelOption) =>
      classLevelOption.value.includes('EM -')
    )
  },
  {
    key: 'EM_ESP',
    value: i18n().modules.hub.classroom.abbreviatedSegmentClassLevel.EM_ESP,
    classLevels: CLASS_LEVEL_OPTIONS.filter((classLevelOption) =>
      classLevelOption.value.includes('EM Especial -')
    )
  },
  {
    key: 'EJA',
    value: i18n().modules.hub.classroom.abbreviatedSegmentClassLevel.EJA,
    classLevels: CLASS_LEVEL_OPTIONS.filter((classLevelOption) =>
      classLevelOption.value.includes('EJA -')
    )
  },
  {
    key: 'EPRO',
    value: i18n().modules.hub.classroom.abbreviatedSegmentClassLevel.EPRO,
    classLevels: CLASS_LEVEL_OPTIONS.filter((classLevelOption) =>
      classLevelOption.value.includes('Educação Profissional')
    )
  }
]
export const allClassLevelsWithFinalValue = CLASS_LEVEL_PER_SEGMENT.flatMap((segment) =>
  segment.classLevels.map((classLevel) => ({
    key: classLevel.key,
    value: `${segment.value} - ${classLevel.finalValue.split(' - ')[0]}`
  }))
)
