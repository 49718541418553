import { Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function ShowChartIcon({ fill, size = '$lg', css, ...restProps }: IconProps): JSX.Element {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      css={{
        fill: 'none',
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        ...css
      }}
      {...restProps}
    >
      <Path
        d="M3.5 18.4898L9.5 12.4798L13.5 16.4798L22 6.91977L20.59 5.50977L13.5 13.4798L9.5 9.47976L2 16.9898L3.5 18.4898Z"
        css={{ fill }}
      />
    </Svg>
  )
}
