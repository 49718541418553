export const defaultRoles = [
  { name: 'Todos os usuários', key: 'all' },
  { name: 'Aluno', key: 'aluno' },
  { name: 'Responsável', key: 'responsavel' },
  { name: 'Professor', key: 'professor' },
  { name: 'Instituição', key: 'instituicao' },
  { name: 'Diretor', key: 'diretor' },
  { name: 'Coordenador', key: 'coordenador' },
  { name: 'Administrador', key: 'administrador' },
  { name: 'Colaborador', key: 'colaborador' }
]
