export enum ClassLevelCode {
  CHILD_EDUCATION_1 = 'EI - Grupo 1',
  CHILD_EDUCATION_2 = 'EI - Grupo 2',
  CHILD_EDUCATION_3 = 'EI - Grupo 3',
  CHILD_EDUCATION_4 = 'EI - Grupo 4',
  CHILD_EDUCATION_5 = 'EI - Grupo 5',
  CHILD_EDUCATION = 'Educação Infantil',
  FIRST_YEAR = 'EF1 - 1º ano',
  SECOND_YEAR = 'EF1 - 2º ano',
  THIRD_YEAR = 'EF1 - 3º ano',
  FORTH_YEAR = 'EF1 - 4º ano',
  FIFTH_YEAR = 'EF1 - 5º ano',
  SIXTH_YEAR = 'EF2 - 6º ano',
  SEVENTH_YEAR = 'EF2 - 7º ano',
  EIGHTH_YEAR = 'EF2 - 8º ano',
  NINTH_YEAR = 'EF2 - 9º ano',
  FIRST_YEAR_ESP = 'EF1 Especial - 1º ano',
  SECOND_YEAR_ESP = 'EF1 Especial - 2º ano',
  THIRD_YEAR_ESP = 'EF1 Especial - 3º ano',
  FORTH_YEAR_ESP = 'EF1 Especial - 4º ano',
  FIFTH_YEAR_ESP = 'EF1 Especial - 5º ano',
  SIXTH_YEAR_ESP = 'EF2 Especial - 6º ano',
  SEVENTH_YEAR_ESP = 'EF2 Especial - 7º ano',
  EIGHTH_YEAR_ESP = 'EF2 Especial - 8º ano',
  NINTH_YEAR_ESP = 'EF2 Especial - 9º ano',
  FIRST_YEAR_EM = 'EM - 1ª Série',
  SECOND_YEAR_EM = 'EM - 2ª Série',
  THIRD_YEAR_EM = 'EM - 3ª Série',
  FIRST_YEAR_EM_ESP = 'EM Especial - 1ª Série',
  SECOND_YEAR_EM_ESP = 'EM Especial - 2ª Série',
  THIRD_YEAR_EM_ESP = 'EM Especial - 3ª Série',
  CHILD_EDUCATION_1_SP = 'EI Especial - Grupo 1',
  CHILD_EDUCATION_2_SP = 'EI Especial - Grupo 2',
  CHILD_EDUCATION_3_SP = 'EI Especial - Grupo 3',
  CHILD_EDUCATION_4_SP = 'EI Especial - Grupo 4',
  CHILD_EDUCATION_5_SP = 'EI Especial - Grupo 5',
  EJAEF_ONE = 'EJA - EF1',
  EJAEF_SECOND = 'EJA - EF2',
  EJA_EM = 'EJA - EM',
  PRO = 'Educação Profissional',
  EPEM = 'Educação Profissional Integrada ao Ensino Médio'
}

export interface ClassLevel {
  id: number
  name: string
  code: keyof typeof ClassLevelCode
  segment: string
}

export interface ClassLevelFormatted extends ClassLevel {
  fullNameClassLevelFormatted: string
}

export interface Classroom {
  id: number
  name: string
  code: string
  students: number
  teathers: number
  level: {
    id: number
    name: string
    code: keyof typeof ClassLevelCode
    segment: string
  }
}

export interface ClassroomFormatted extends Classroom {
  levelFormatted: {
    id: number
    name: string
    code: string
    segment: string
  }
}

export interface Student {
  id: string
  name: string
  code: string
  login: string
  online: boolean
  picture: string
  allowedImageUseOnEdtech: boolean
  enrollmentDate: string
}

export interface AvailableClassroom {
  name?: 'available' | 'unavailable'
  code?: 'available' | 'unavailable'
}
