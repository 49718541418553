import { Div } from '@positivote/design-system/components/Div'
import { Typography } from '@positivote/design-system/components/Typography'

import { Mascot } from './Mascot'

interface EmptyListProps {
  CustomMascot?: React.ReactNode
  title: string
  subTitle?: string
}

export function EmptyReport({ CustomMascot, title, subTitle }: EmptyListProps): JSX.Element {
  return (
    <Div
      css={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '$2xs'
      }}
    >
      {CustomMascot ?? <Mascot />}
      <Typography
        data-testid="Typography-emptyListTitle"
        variant="headlineSmall"
        css={{ textAlign: 'center', color: '$on-surface' }}
      >
        {title}
      </Typography>
      {subTitle && (
        <Typography
          data-testid="Typography-emptyListSubTitle"
          variant="bodyLarge"
          css={{ textAlign: 'center', maxWidth: '330px', color: '$on-surface-variant' }}
        >
          {subTitle}
        </Typography>
      )}
    </Div>
  )
}
