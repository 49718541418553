import { i18n } from '@/common/i18n'
import { apiService } from '@/common/services'

import { ListBannerJsonApiResult, ListBannerJsonResult } from './contracts'

export async function listBannerJsonService(): Promise<ListBannerJsonResult[]> {
  const data = await apiService<null, ListBannerJsonApiResult[]>({
    resource: i18n().common.resources.listJsonBanner,
    method: 'get',
    url: `/wellcome-banner-hub.json`,
    baseURL: import.meta.env.VITE_HUB_BANNER_URL,
    headers: {
      'Cache-Control': 'no-cache'
    }
  })

  return data
}
