import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Select } from '@positivote/design-system/components/Select'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { GroupsIcon } from '@positivote/design-system/icons/Groups'
import { InfoIcon } from '@positivote/design-system/icons/Info'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { useState } from 'react'
// eslint-disable-next-line import/default
import ReactApexChart from 'react-apexcharts'

import { EmptyReport } from '@/common/components/EmptyReport'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { PeopleIcon } from '@/fixme/icons/People'
import { SchoolOrgIcon } from '@/fixme/icons/SchoolOrg'
import { TrendingDownIcon } from '@/fixme/icons/TrendingDown'
import { TrendingUpIcon } from '@/fixme/icons/TrendingUp'
import { useAuth } from '@/modules/hub/auth/contexts'
import { OrganizationKind } from '@/modules/hub/organizations/contracts'

import { defaultLineGraphics } from '../../constants/graphics'
import { ListAccessHookParams, ListAccessSummaryHookParams } from '../../contracts/hook'
import { defaultRoles } from '../../contracts/model'
import {
  useListAccess,
  useListAccessSummary,
  useListApplication,
  useListUsersManagement
} from '../../hooks'

export function Overview(): JSX.Element {
  changePageTitle(i18n().modules.hub.reports.pages.list.pageTitle)
  const { profile } = useAuth()

  const [accessParams, setAccessParams] = useState<ListAccessHookParams['model']>({
    institutionId: profile?.organization.code as unknown as string,
    role: profile?.role.code as unknown as string,
    roleFilter: 'all',
    applicationId: 0,
    userId: profile?.id
  })

  const [usersManagementParams, setUsersManagementParams] = useState<ListAccessHookParams['model']>(
    {
      institutionId: profile?.organization.code as unknown as string,
      role: profile?.role.code as unknown as string,
      roleFilter: 'all',
      applicationId: 0,
      userId: profile?.id
    }
  )

  const [accessSummaryParams] = useState<ListAccessSummaryHookParams['model']>({
    institutionId: profile?.organization.code as unknown as string,
    role: profile?.role.code as unknown as string,
    userId: profile?.id
  })

  // const jsonObject = {
  //   role: 'adminstrator',
  //   org_code: '02f4c5b4-adfc-41e0-b16e-f68fe34a417b',
  //   org_name: 'Escola X',
  //   org_type: 'school',
  //   user_id: 123456,
  //   user_name: 'John Doe',
  //   token: 'JWT'
  // }

  // const jsonString = JSON.stringify(jsonObject)
  // const base64String = btoa(jsonString)

  const listAccessSummary = useListAccessSummary({
    model: accessSummaryParams,
    queryOptions: {
      enabled: !!profile
    }
  })

  const access = useListAccess({
    model: {
      ...accessParams,
      roleFilter: accessParams.roleFilter === 'all' ? undefined : accessParams.roleFilter,
      applicationId: accessParams.applicationId === 0 ? undefined : accessParams.applicationId
    },
    queryOptions: {
      enabled: !!profile
    }
  })

  const listApplications = useListApplication({
    model: {
      institutionId: profile?.organization.code as unknown as string
    }
  })

  const listUsersManagement = useListUsersManagement({
    model: {
      ...usersManagementParams,
      roleFilter:
        usersManagementParams.roleFilter === 'all' ? undefined : usersManagementParams.roleFilter,
      applicationId:
        usersManagementParams.applicationId === 0 ? undefined : usersManagementParams.applicationId
    }
  })

  const listApplicationsFormatted = [
    { id: 0, name: 'Todas as aplicações' },
    ...(listApplications.data ?? [])
  ]

  const isLoading =
    listAccessSummary.isFetching || listUsersManagement.isFetching || access.isFetching

  const isSchool = profile?.organization.kindId === OrganizationKind.SCHOOL

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      {(isLoading && !access.data) || !listUsersManagement.data || !listAccessSummary.data ? (
        <Div
          css={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Loader size={80} />
        </Div>
      ) : (
        <>
          {!listAccessSummary.data.application.name.length ? (
            <>
              <EmptyReport
                title="Sua instituição ainda não possui relatórios..."
                subTitle="Precisamos de mais dados de o uso do HUB na sua escola para criar os relatórios."
              />
            </>
          ) : (
            <Div
              css={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                padding: '$lg',
                overflowY: 'auto',
                gap: '$lg',
                '@sm': { padding: '$md' }
              }}
            >
              <Div
                css={{ padding: '$md', backgroundColor: '$surface-variant', borderRadius: '$lg' }}
              >
                <Typography variant="bodyLarge">
                  Bem-vindo aos relatórios Hub! Para informações detalhadas, acesse os relatórios na
                  área de interesse.
                </Typography>
              </Div>
              <Divider />
              <Typography variant="bodySmall" css={{ color: '$on-surface-variant' }}>
                Dados de acessos únicos consolidados nos últimos 30 dias.
              </Typography>
              <Div css={{ display: 'flex', flexDirection: 'column', gap: '$lg' }}>
                {profile?.role.code === 'professor' && !listAccessSummary.data.classes ? (
                  <>
                    <Div css={{ display: 'flex', gap: '$lg' }}>
                      <Div
                        css={{
                          backgroundColor: '$surface-2',
                          padding: '$lg',
                          borderRadius: '$lg',
                          display: 'flex',
                          gap: '$md',
                          alignItems: 'center',
                          width: '50%'
                        }}
                      >
                        <Image
                          alt="2"
                          height={64}
                          width={64}
                          src={listAccessSummary.data.application.icon}
                          css={{
                            borderRadius: '$full',
                            borderWidth: '$thin',
                            borderStyle: 'solid',
                            borderColor: '$outline-variant'
                          }}
                        />
                        <Div>
                          <Typography variant="headlineSmall">
                            {listAccessSummary.data.application.name}
                          </Typography>
                          <Typography variant="bodyMedium" css={{ color: '$on-surface-variant' }}>
                            é a aplicação com mais acessos únicos das escolas
                          </Typography>
                        </Div>
                      </Div>
                      <Div
                        css={{
                          backgroundColor: '$surface-2',
                          padding: '$lg',
                          borderRadius: '$lg',
                          display: 'flex',
                          gap: '$md',
                          alignItems: 'center',
                          width: '50%'
                        }}
                      >
                        <IconWrapper size={64} css={{ backgroundColor: '$primary-container' }}>
                          <PeopleIcon size={38} fill="$on-primary-container" />
                        </IconWrapper>
                        <Div>
                          <Typography variant="headlineSmall">
                            {listAccessSummary.data.accessingApps.percentage}%
                          </Typography>
                          <Typography variant="bodyMedium" css={{ color: '$on-surface-variant' }}>
                            dos usuários cadastrados não acessaram aplicações educacionais
                          </Typography>
                        </Div>
                      </Div>
                    </Div>
                    <Div
                      css={{
                        borderRadius: '$lg',
                        display: 'flex',
                        gap: '$md',
                        borderWidth: '$thin',
                        borderStyle: 'solid',
                        borderColor: '$outline-variant',
                        flex: 1,
                        width: '100%'
                      }}
                    >
                      <Div
                        css={{
                          display: 'flex',
                          flex: 1,
                          alignItems: 'center',
                          padding: '$lg',
                          gap: '$md'
                        }}
                      >
                        <IconWrapper size={64} css={{ backgroundColor: '#006E1C29' }}>
                          <PersonIcon size={38} fill="$success" />
                        </IconWrapper>
                        <Div>
                          <Typography variant="headlineSmall">
                            {listAccessSummary.data.students?.highest}
                          </Typography>
                          <Typography variant="bodyMedium" css={{ color: '$on-surface-variant' }}>
                            É o estudante com o maior índice de acesso
                          </Typography>
                        </Div>
                      </Div>
                      <Div
                        css={{
                          borderWidth: '$thin',
                          borderStyle: 'solid',
                          borderColor: '$outline-variant'
                        }}
                      ></Div>
                      <Div
                        css={{
                          display: 'flex',
                          flex: 1,
                          alignItems: 'center',
                          padding: '$lg',
                          gap: '$md'
                        }}
                      >
                        <IconWrapper size={64} css={{ backgroundColor: '#BB161429' }}>
                          <PersonIcon size={38} fill="$critical" />
                        </IconWrapper>
                        <Div>
                          <Typography variant="headlineSmall">
                            {listAccessSummary.data.students?.lowest}
                          </Typography>
                          <Typography variant="bodyMedium" css={{ color: '$on-surface-variant' }}>
                            É a estudante com o menor índice de acesso
                          </Typography>
                        </Div>
                      </Div>
                    </Div>
                  </>
                ) : (
                  <>
                    <Div css={{ display: 'flex', gap: '$lg' }}>
                      <Div
                        css={{
                          backgroundColor: '$surface-2',
                          padding: '$lg',
                          borderRadius: '$lg',
                          display: 'flex',
                          gap: '$md',
                          alignItems: 'center',
                          width: '30%'
                        }}
                      >
                        <Image
                          alt="2"
                          height={64}
                          width={64}
                          src={listAccessSummary.data.application.icon}
                          css={{
                            borderRadius: '$full',
                            borderWidth: '$thin',
                            borderStyle: 'solid',
                            borderColor: '$outline-variant'
                          }}
                        />
                        <Div>
                          <Typography variant="headlineSmall">
                            {listAccessSummary.data.application.name}
                          </Typography>
                          <Typography variant="bodyMedium" css={{ color: '$on-surface-variant' }}>
                            é a aplicação com mais acessos únicos das escolas
                          </Typography>
                        </Div>
                      </Div>
                      <Div
                        css={{
                          borderRadius: '$lg',
                          display: 'flex',
                          gap: '$md',
                          borderWidth: '$thin',
                          borderStyle: 'solid',
                          borderColor: '$outline-variant',
                          flex: 1,
                          width: '100%'
                        }}
                      >
                        <Div
                          css={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            padding: '$lg',
                            gap: '$md'
                          }}
                        >
                          {isSchool && profile.role.code !== 'professor' && (
                            <>
                              <IconWrapper size={64} css={{ backgroundColor: '#006E1C29' }}>
                                <TrendingUpIcon size={38} fill="$success" />
                              </IconWrapper>
                              <Div>
                                <Typography variant="headlineSmall">
                                  {listAccessSummary.data.grades.highest}
                                </Typography>
                                <Typography
                                  variant="bodyMedium"
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  É o ano escolar com o maior índice de acesso
                                </Typography>
                              </Div>
                            </>
                          )}
                          {isSchool &&
                            profile.role.code === 'professor' &&
                            listAccessSummary.data.classes && (
                              <>
                                <IconWrapper size={64} css={{ backgroundColor: '#006E1C29' }}>
                                  <GroupsIcon size={38} fill="$success" />
                                </IconWrapper>
                                <Div>
                                  <Typography variant="headlineSmall">
                                    {listAccessSummary.data.classes.highest}
                                  </Typography>
                                  <Typography
                                    variant="bodyMedium"
                                    css={{ color: '$on-surface-variant' }}
                                  >
                                    É o ano escolar com o maior índice de acesso
                                  </Typography>
                                </Div>
                              </>
                            )}
                          {!isSchool && listAccessSummary.data.schools && (
                            <>
                              <IconWrapper size={64} css={{ backgroundColor: '#006E1C29' }}>
                                <SchoolOrgIcon size={38} fill="$success" />
                              </IconWrapper>
                              <Div>
                                <Typography variant="headlineSmall">
                                  {listAccessSummary.data.schools.highest}
                                </Typography>
                                <Typography
                                  variant="bodyMedium"
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  É a escola com o maior índice de acesso
                                </Typography>
                              </Div>
                            </>
                          )}

                          {!isSchool && !listAccessSummary.data.schools && (
                            <>
                              <IconWrapper size={64} css={{ backgroundColor: '#006E1C29' }}>
                                <TrendingUpIcon size={38} fill="$success" />
                              </IconWrapper>
                              <Div>
                                <Typography variant="headlineSmall">
                                  {listAccessSummary.data.grades.highest}
                                </Typography>
                                <Typography
                                  variant="bodyMedium"
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  É o ano escolar com o maior índice de acesso
                                </Typography>
                              </Div>
                            </>
                          )}
                        </Div>
                        <Div
                          css={{
                            borderWidth: '$thin',
                            borderStyle: 'solid',
                            borderColor: '$outline-variant'
                          }}
                        ></Div>
                        <Div
                          css={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            padding: '$lg',
                            gap: '$md'
                          }}
                        >
                          {isSchool && profile.role.code !== 'professor' && (
                            <>
                              <IconWrapper size={64} css={{ backgroundColor: '#BB161429' }}>
                                <TrendingDownIcon size={38} fill="$critical" />
                              </IconWrapper>
                              <Div>
                                <Typography variant="headlineSmall">
                                  {listAccessSummary.data.grades.lowest}
                                </Typography>
                                <Typography
                                  variant="bodyMedium"
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  É o ano escolar com o menor índice de acesso
                                </Typography>
                              </Div>
                            </>
                          )}
                          {isSchool && profile.role.code === 'professor' && (
                            <>
                              <IconWrapper size={64} css={{ backgroundColor: '#BB161429' }}>
                                <GroupsIcon size={38} fill="$critical" />
                              </IconWrapper>
                              <Div>
                                <Typography variant="headlineSmall">
                                  {listAccessSummary.data.classes?.highest}
                                </Typography>
                                <Typography
                                  variant="bodyMedium"
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  É a turma com o maior índice de acesso
                                </Typography>
                              </Div>
                            </>
                          )}
                          {!isSchool && listAccessSummary.data.schools && (
                            <>
                              <IconWrapper size={64} css={{ backgroundColor: '#BB161429' }}>
                                <SchoolOrgIcon size={38} fill="$critical" />
                              </IconWrapper>
                              <Div>
                                <Typography variant="headlineSmall">
                                  {listAccessSummary.data.schools.lowest}
                                </Typography>
                                <Typography
                                  variant="bodyMedium"
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  É a escola com o menor índice de acesso
                                </Typography>
                              </Div>
                            </>
                          )}

                          {!isSchool && !listAccessSummary.data.schools && (
                            <>
                              <IconWrapper size={64} css={{ backgroundColor: '#BB161429' }}>
                                <TrendingDownIcon size={38} fill="$critical" />
                              </IconWrapper>
                              <Div>
                                <Typography variant="headlineSmall">
                                  {listAccessSummary.data.grades.lowest}
                                </Typography>
                                <Typography
                                  variant="bodyMedium"
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  É a escola com o menor índice de acesso
                                </Typography>
                              </Div>
                            </>
                          )}
                        </Div>
                      </Div>
                    </Div>
                    <Div css={{ display: 'flex', gap: '$lg' }}>
                      <Div
                        css={{
                          backgroundColor: '$surface-2',
                          padding: '$lg',
                          borderRadius: '$lg',
                          display: 'flex',
                          gap: '$md',
                          alignItems: 'center',
                          width: '30%'
                        }}
                      >
                        <IconWrapper size={64} css={{ backgroundColor: '$primary-container' }}>
                          <PeopleIcon size={38} fill="$on-primary-container" />
                        </IconWrapper>
                        <Div>
                          <Typography variant="headlineSmall">
                            {listAccessSummary.data.accessingApps.percentage}%
                          </Typography>
                          <Typography variant="bodyMedium" css={{ color: '$on-surface-variant' }}>
                            dos usuários cadastrados não acessaram aplicações educacionais
                          </Typography>
                        </Div>
                      </Div>
                      <Div
                        css={{
                          borderRadius: '$lg',
                          display: 'flex',
                          gap: '$md',
                          borderWidth: '$thin',
                          borderStyle: 'solid',
                          borderColor: '$outline-variant',
                          flex: 1
                        }}
                      >
                        <Div
                          css={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            padding: '$lg',
                            gap: '$md'
                          }}
                        >
                          {isSchool && profile.role.code !== 'professor' && (
                            <>
                              <IconWrapper size={64} css={{ backgroundColor: '#006E1C29' }}>
                                <GroupsIcon size={38} fill="$success" />
                              </IconWrapper>
                              <Div>
                                <Typography variant="headlineSmall">
                                  {listAccessSummary.data.classes?.highest}
                                </Typography>
                                <Typography
                                  variant="bodyMedium"
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  É a turma com o maior índice de acesso
                                </Typography>
                              </Div>
                            </>
                          )}
                          {isSchool && profile.role.code === 'professor' && (
                            <>
                              <IconWrapper size={64} css={{ backgroundColor: '#006E1C29' }}>
                                <PersonIcon size={38} fill="$success" />
                              </IconWrapper>
                              <Div>
                                <Typography variant="headlineSmall">
                                  {listAccessSummary.data.students?.highest}
                                </Typography>
                                <Typography
                                  variant="bodyMedium"
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  É a turma com o maior índice de acesso
                                </Typography>
                              </Div>
                            </>
                          )}
                          {!isSchool && listAccessSummary.data.schools && (
                            <>
                              <IconWrapper size={64} css={{ backgroundColor: '#006E1C29' }}>
                                <TrendingUpIcon size={38} fill="$success" />
                              </IconWrapper>
                              <Div>
                                <Typography variant="headlineSmall">
                                  {listAccessSummary.data.grades.highest}
                                </Typography>
                                <Typography
                                  variant="bodyMedium"
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  É o ano escolar com o maior índice de acesso
                                </Typography>
                              </Div>
                            </>
                          )}

                          {!isSchool && !listAccessSummary.data.schools && (
                            <>
                              <IconWrapper size={64} css={{ backgroundColor: '#006E1C29' }}>
                                <GroupsIcon size={38} fill="$success" />
                              </IconWrapper>
                              <Div>
                                <Typography variant="headlineSmall">
                                  {listAccessSummary.data.classes?.highest}
                                </Typography>
                                <Typography
                                  variant="bodyMedium"
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  É a turma com o maior índice de acesso
                                </Typography>
                              </Div>
                            </>
                          )}
                        </Div>
                        <Div
                          css={{
                            borderWidth: '$thin',
                            borderStyle: 'solid',
                            borderColor: '$outline-variant'
                          }}
                        ></Div>
                        <Div
                          css={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            padding: '$lg',
                            gap: '$md'
                          }}
                        >
                          {isSchool && profile.role.code !== 'professor' && (
                            <>
                              <IconWrapper size={64} css={{ backgroundColor: '#BB161429' }}>
                                <GroupsIcon size={38} fill="$critical" />
                              </IconWrapper>
                              <Div>
                                <Typography variant="headlineSmall">
                                  {listAccessSummary.data.classes?.lowest}
                                </Typography>
                                <Typography
                                  variant="bodyMedium"
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  É a turma com o menor índice de acesso
                                </Typography>
                              </Div>
                            </>
                          )}
                          {isSchool && profile.role.code === 'professor' && (
                            <>
                              <IconWrapper size={64} css={{ backgroundColor: '#BB161429' }}>
                                <PersonIcon size={38} fill="$critical" />
                              </IconWrapper>
                              <Div>
                                <Typography variant="headlineSmall">
                                  {listAccessSummary.data.students?.lowest}
                                </Typography>
                                <Typography
                                  variant="bodyMedium"
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  É a turma com o menor índice de acesso
                                </Typography>
                              </Div>
                            </>
                          )}
                          {!isSchool && listAccessSummary.data.schools && (
                            <>
                              <IconWrapper size={64} css={{ backgroundColor: '#BB161429' }}>
                                <TrendingDownIcon size={38} fill="$critical" />
                              </IconWrapper>
                              <Div>
                                <Typography variant="headlineSmall">
                                  {listAccessSummary.data.grades.lowest}
                                </Typography>
                                <Typography
                                  variant="bodyMedium"
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  É a turma com o menor índice de acesso
                                </Typography>
                              </Div>
                            </>
                          )}
                          {!isSchool && !listAccessSummary.data.schools && (
                            <>
                              <IconWrapper size={64} css={{ backgroundColor: '#BB161429' }}>
                                <GroupsIcon size={38} fill="$critical" />
                              </IconWrapper>
                              <Div>
                                <Typography variant="headlineSmall">
                                  {listAccessSummary.data.classes?.lowest}
                                </Typography>
                                <Typography
                                  variant="bodyMedium"
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  É a turma com o menor índice de acesso
                                </Typography>
                              </Div>
                            </>
                          )}
                        </Div>
                      </Div>
                    </Div>
                  </>
                )}

                <Div
                  css={{
                    flexDirection: 'column',
                    display: 'flex',
                    gap: '$lg',
                    padding: '$lg',
                    borderRadius: '$lg',
                    borderWidth: '$thin',
                    borderStyle: 'solid',
                    borderColor: '$outline-variant'
                  }}
                >
                  <Div css={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="titleLarge">Acessos</Typography>
                    {/* <LinkButton
                  LeadingIcon={<KeyboardDoubleArrowRightIcon />}
                  css={{ background: '$surface-2' }}
                >
                  Ir para Relatórios de Acessos
                </LinkButton> */}
                  </Div>
                  <Div css={{ display: 'flex', gap: '$lg' }}>
                    <Select
                      label="Selecione o tipo de usuário"
                      variant="outlined"
                      optionKeyField="key"
                      optionTitleField="name"
                      value={accessParams.roleFilter}
                      options={defaultRoles}
                      css={{ width: '300px' }}
                      onChange={(option) =>
                        setAccessParams((oldData) => ({ ...oldData, roleFilter: option?.key }))
                      }
                    />
                    <Select
                      disabled={listApplications.isFetching}
                      label="Selecione a aplicação"
                      variant="outlined"
                      optionKeyField="id"
                      optionTitleField="name"
                      value={accessParams.applicationId}
                      options={listApplicationsFormatted}
                      css={{ width: '300px' }}
                      onChange={(option) =>
                        setAccessParams((oldData) => ({ ...oldData, applicationId: option?.id }))
                      }
                    />
                  </Div>
                  <Div
                    css={{
                      display: 'flex',
                      flex: 1,
                      gap: '$lg',
                      position: 'relative',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minHeight: 280
                    }}
                  >
                    {access.isFetching ? (
                      <>
                        <Loader size={80} />
                      </>
                    ) : (
                      <>
                        <Div
                          css={{
                            width: '100%',
                            padding: '$lg',
                            borderRadius: '$lg',
                            borderWidth: '$thin',
                            borderStyle: 'solid',
                            borderColor: '$outline-variant',
                            position: 'relative'
                          }}
                        >
                          <Div
                            css={{
                              position: 'absolute',
                              top: 24,
                              right: 24,
                              zIndex: 1
                            }}
                          >
                            <Tooltip label="Representa uma sessão individual de um usuário dentro da plataforma. Geralmente, refere-se ao acesso de um único dispositivo, navegador ou conta ativa em determinado momento.">
                              <InfoIcon />
                            </Tooltip>
                          </Div>
                          <ReactApexChart
                            options={{
                              ...defaultLineGraphics.options,
                              title: {
                                ...defaultLineGraphics.options.title,
                                text: 'Acessos únicos'
                              },
                              colors: ['#2196F3'],
                              xaxis: {
                                ...defaultLineGraphics.options.xaxis,
                                categories: access.data?.uniqueXaxisFormatted ?? []
                              }
                            }}
                            series={[
                              {
                                name: 'Acessos',
                                data: access.data?.uniqueSeriesFormatted ?? []
                              }
                            ]}
                            type="line"
                            width="100%"
                            height={280}
                          />
                        </Div>
                        <Div
                          css={{
                            width: '100%',
                            padding: '$lg',
                            borderRadius: '$lg',
                            borderWidth: '$thin',
                            borderStyle: 'solid',
                            borderColor: '$outline-variant'
                          }}
                        >
                          <Div
                            css={{
                              position: 'absolute',
                              top: 24,
                              right: 24,
                              zIndex: 1
                            }}
                          >
                            <Tooltip label="Representa o número total de vezes que uma plataforma foi acessada dentro de um período, podendo incluir múltiplos acessos do mesmo usuário.">
                              <InfoIcon />
                            </Tooltip>
                          </Div>
                          <ReactApexChart
                            options={{
                              ...defaultLineGraphics.options,
                              title: {
                                ...defaultLineGraphics.options.title,
                                text: 'Total de acessos'
                              },
                              colors: ['#A631BA'],
                              xaxis: {
                                ...defaultLineGraphics.options.xaxis,
                                categories: access.data?.totalXaxisFormatted ?? []
                              }
                            }}
                            series={[
                              {
                                name: 'Acessos',
                                data: access.data?.totalSeriesFormatted ?? []
                              }
                            ]}
                            type="line"
                            width="100%"
                            height={280}
                          />
                        </Div>
                      </>
                    )}
                  </Div>
                </Div>
                {profile?.role.code !== 'professor' && (
                  <Div
                    css={{
                      flexDirection: 'column',
                      display: 'flex',
                      gap: '$lg',
                      padding: '$lg',
                      borderRadius: '$lg',
                      borderWidth: '$thin',
                      borderStyle: 'solid',
                      borderColor: '$outline-variant'
                    }}
                  >
                    <Div css={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="titleLarge">Gerencial</Typography>
                      {/* <LinkButton
                    LeadingIcon={<KeyboardDoubleArrowRightIcon />}
                    css={{ background: '$surface-2' }}
                  >
                    Ir para Relatório Gerencial
                  </LinkButton> */}
                    </Div>
                    <Div css={{ display: 'flex', gap: '$lg' }}>
                      <Select
                        label="Selecione o tipo de usuário"
                        variant="outlined"
                        optionKeyField="key"
                        optionTitleField="name"
                        value={usersManagementParams.roleFilter}
                        options={defaultRoles}
                        css={{ width: '300px' }}
                        onChange={(option) =>
                          setUsersManagementParams((oldData) => ({
                            ...oldData,
                            roleFilter: option?.key
                          }))
                        }
                      />
                      <Select
                        label="Selecione a aplicação"
                        variant="outlined"
                        optionKeyField="id"
                        optionTitleField="name"
                        value={usersManagementParams.applicationId}
                        options={listApplicationsFormatted}
                        css={{ width: '300px' }}
                        onChange={(option) =>
                          setUsersManagementParams((oldData) => ({
                            ...oldData,
                            applicationId: option?.id
                          }))
                        }
                      />
                    </Div>
                    <Div
                      css={{
                        display: 'flex',
                        flex: 1,
                        gap: '$lg',
                        position: 'relative',
                        minHeight: 360,
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      {listUsersManagement.isFetching ? (
                        <>
                          <Loader size={80} />
                        </>
                      ) : (
                        <>
                          <Div
                            css={{
                              width: '100%',
                              padding: '$lg',
                              borderRadius: '$lg',
                              borderWidth: '$thin',
                              borderStyle: 'solid',
                              borderColor: '$outline-variant'
                            }}
                          >
                            <Div
                              css={{
                                position: 'absolute',
                                top: 24,
                                right: 24,
                                zIndex: 1
                              }}
                            >
                              <Tooltip label="Representa o número total de usuários cadastrados nas aplicações a cada ano, ao longo do tempo.">
                                <InfoIcon />
                              </Tooltip>
                            </Div>
                            <ReactApexChart
                              options={{
                                ...defaultLineGraphics.options,
                                title: {
                                  ...defaultLineGraphics.options.title,
                                  text: 'Usuários cadastrados'
                                },
                                colors: ['#3F51B5'],
                                xaxis: {
                                  ...defaultLineGraphics.options.xaxis,
                                  categories: listUsersManagement.data.xaxisTotalFormatted
                                }
                              }}
                              series={[
                                {
                                  name: 'Acessos',
                                  data: listUsersManagement.data.seriesDateFormatted
                                }
                              ]}
                              type="bar"
                              width="100%"
                              height="360"
                            />
                          </Div>
                        </>
                      )}
                    </Div>
                  </Div>
                )}
              </Div>
            </Div>
          )}
        </>
      )}
    </Main>
  )
}
