import { Accordion } from '@positivote/design-system/components/Accordion'
import { Div } from '@positivote/design-system/components/Div'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { Main } from '@positivote/design-system/components/Main'
import { Select } from '@positivote/design-system/components/Select'
import { TextField, TextFieldRef } from '@positivote/design-system/components/TextField'
import { Typography } from '@positivote/design-system/components/Typography'
import { useTheme } from '@positivote/design-system/hooks'
import { CloseIcon } from '@positivote/design-system/icons/Close'
import { KeyboardArrowRightIcon } from '@positivote/design-system/icons/KeyboardArrowRight'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { Breakpoint } from '@positivote/design-system/theme'
import { useRef, useState } from 'react'

import { AppBar } from '@/common/components/AppBar'
import { Banner } from '@/common/components/Banner'
import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import { WrappedLoader } from '@/common/components/WrappedLoader'
import { changePageTitle, debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { Footer } from '@/common/layouts/Footer'
import { AppCard } from '@/modules/hub/applications/components/AppCard'
import { HubLabAppCard } from '@/modules/hub/applications/components/HubLabAppCard'
import { ToolCard } from '@/modules/hub/applications/components/ToolCard'
import { ApplicationCategory } from '@/modules/hub/applications/contracts/models'
import { useListApplication } from '@/modules/hub/applications/hooks'
import { useAuth } from '@/modules/hub/auth/contexts'
import { useListClassLevel } from '@/modules/hub/classroom/hooks'

export function HomeApplications(): JSX.Element {
  changePageTitle(i18n().modules.hub.applications.pages.list.pageTitle)
  const { breakpoint } = useTheme()
  const { profile } = useAuth()
  const searchTextFieldRef = useRef<TextFieldRef>(null)

  const [applicationIdLoading, setApplicationIdLoading] = useState<number | null>(null)
  const [searchText, setSearchText] = useState('')
  const [isShowingTextField, setIsShowingTextField] = useState(false)
  const [selectClassLevel, setSelectClassLevel] = useState<{ key: string; value: string }>({
    key: 'all',
    value: i18n().modules.hub.applications.pages.list.appBar.selectSearch.defaultLabel
  })

  const { session, isLoading: authIsLoading } = useAuth()

  const applicationsIsEnabled = !!session?.userId
  const hubLabClientIds = [
    'hub.lab.mylab',
    'hub.lab.libraries',
    'hub.lab.colab',
    'hub.lab.storylab',
    'hub.lab.idealab',
    'hub.lab.medialab'
  ]

  const listClassLevel = useListClassLevel({ model: {} })

  const { data: applicationData, isLoading: applicationIsLoading } = useListApplication({
    model: {
      size: 100,
      search: searchText.trim() || undefined,
      page: 1,
      serie: selectClassLevel.key === 'all' ? undefined : selectClassLevel.key
    },
    queryOptions: {
      enabled: applicationsIsEnabled
    }
  })
  const firstLineHubLabColumns = 2

  const parsedApplicationsHubLab = (
    applicationData?.registers.filter(
      (application) =>
        application.categoryId === ApplicationCategory.HUB_LAB &&
        hubLabClientIds.includes(application.clientId)
    ) ?? []
  ).sort((a, b) => {
    return hubLabClientIds.indexOf(a.clientId) - hubLabClientIds.indexOf(b.clientId)
  })

  const parsedApplications =
    applicationData?.registers.filter(
      (application) =>
        ![ApplicationCategory.COMPLEMENTARY, ApplicationCategory.HUB_LAB].includes(
          application.categoryId
        )

      // TODO: use above when fix category id in data base
      // application.categoryId === ApplicationCategory.INTEGRATED
    ) ?? []

  const parsedComplementaryTools =
    applicationData?.registers.filter(
      (application) => application.categoryId === ApplicationCategory.COMPLEMENTARY
    ) ?? []

  const levels = [
    {
      key: 'all',
      value: i18n().modules.hub.applications.pages.list.appBar.selectSearch.defaultLabel
    },
    ...(listClassLevel.data?.registers ?? []).map((level) => ({
      key: level.code,
      value: level.name
    }))
  ]

  const hasAnyApplication =
    !!parsedApplicationsHubLab.length ||
    !!parsedApplications.length ||
    !!parsedComplementaryTools.length

  const hasAnyFilter = selectClassLevel.key !== 'all' || !!searchText

  const isLoading = (applicationIsLoading && applicationsIsEnabled) || authIsLoading

  function handleChangeText(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value
    debounceEvent(() => {
      setSearchText(value)
    })()
  }

  function clearSearchText(): void {
    setSearchText('')
    searchTextFieldRef.current?.clearInput()
    setIsShowingTextField(false)
  }

  function handleShowTextField(): void {
    setIsShowingTextField(true)
    setSelectClassLevel({
      key: 'all',
      value: i18n().modules.hub.applications.pages.list.appBar.selectSearch.defaultLabel
    })
  }

  function SearchTextField(): JSX.Element {
    return (
      <TextField
        ref={searchTextFieldRef}
        className="AppBar-TextField"
        data-testid="appBarTextField"
        variant="filled"
        label={i18n().modules.hub.applications.pages.list.appBar.searchLabel}
        leadingIcon={{ icon: SearchIcon }}
        css={{ maxWidth: 300, width: '100%', '@xs': { maxWidth: '100%' } }}
        inputProps={{ id: 'AppBar-TextField', onChange: handleChangeText }}
        trailingIcon={{ icon: CloseIcon, onClick: clearSearchText }}
      />
    )
  }

  function ClassLevelSelect(): JSX.Element {
    return (
      <Select
        className="AppBar-Select"
        data-testid="appBarSelect"
        variant="filled"
        label={i18n().modules.hub.applications.pages.list.appBar.selectSearch.label}
        optionKeyField="key"
        optionTitleField="value"
        options={levels}
        value={selectClassLevel.key}
        css={{ maxWidth: 240, width: '100%', '@xs': { maxWidth: '100%' } }}
        onChange={(option) => option && setSelectClassLevel(option)}
      />
    )
  }

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
      <AppBar
        title={i18n().modules.hub.applications.pages.list.appBar.title}
        trailingElement={
          breakpoint === Breakpoint.xs ? (
            <Div css={{ display: 'flex', alignItems: 'center', width: '100%', gap: '$2xs' }}>
              {isShowingTextField || profile?.role.code === 'aluno' ? (
                // DOCS: needs to call as function to avoid loose focus
                SearchTextField()
              ) : (
                <>
                  <IconButton variant="standard" onClick={handleShowTextField}>
                    <SearchIcon />
                  </IconButton>
                  {
                    // DOCS: needs to call as function to avoid loose focus
                    profile?.role.code !== 'aluno' && ClassLevelSelect()
                  }
                </>
              )}
            </Div>
          ) : (
            <>
              {
                // DOCS: needs to call as function to avoid loose focus
                SearchTextField()
              }
              {
                // DOCS: needs to call as function to avoid loose focus
                profile?.role.code !== 'aluno' && ClassLevelSelect()
              }
            </>
          )
        }
        breadcrumbItems={
          !searchText
            ? undefined
            : [
                {
                  label: i18n().modules.hub.applications.pages.list.appBar.breadcrumbs.overview,
                  onClick: clearSearchText
                },
                { label: i18n().modules.hub.applications.pages.list.appBar.breadcrumbs.search }
              ]
        }
      />

      <Div css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto' }}>
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            padding: '$lg',
            '@sm': { padding: '$md' }
          }}
        >
          {['professor', 'coordenador', 'diretor', 'administrador'].includes(
            profile?.role.code ?? ''
          ) && <Banner />}
          {isLoading && !searchText && <WrappedLoader />}
          {!hasAnyApplication && !applicationIsLoading ? (
            hasAnyFilter ? (
              <EmptyList
                title={i18n().modules.hub.applications.pages.list.emptyResult.title}
                subTitle={i18n().modules.hub.applications.pages.list.emptyResult.subTitle}
              />
            ) : (
              <EmptySearch />
            )
          ) : isLoading && searchText ? (
            <>
              <Typography variant="titleLarge" css={{ marginBottom: '$md', color: '$on-surface' }}>
                {i18n().modules.hub.applications.pages.list.searching}
              </Typography>
              <WrappedLoader />
            </>
          ) : (
            <>
              {searchText && (
                <Typography
                  variant="titleLarge"
                  css={{ marginBottom: '$md', color: '$on-surface' }}
                >
                  {i18n().modules.hub.applications.pages.list.results}
                </Typography>
              )}
              {!!parsedApplicationsHubLab.length && (
                <Accordion
                  isOpen={true}
                  Icon={KeyboardArrowRightIcon}
                  Summary={
                    <Typography
                      variant={breakpoint <= Breakpoint.sm ? 'titleMedium' : 'titleLarge'}
                      css={{ color: '$on-surface' }}
                    >
                      {i18n().modules.hub.applications.pages.list.hubLab}
                    </Typography>
                  }
                  css={{
                    borderStyle: 'none',
                    backgroundColor: '$surface-variant',
                    padding: '$lg',
                    '& .Accordion-Summary': {
                      height: 32,
                      padding: '$none',
                      gap: '$2xs'
                    },
                    '& .Accordion-Details': {
                      marginTop: '$md',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '$md',
                      flexWrap: 'wrap'
                    },
                    '@sm': { padding: '$md' }
                  }}
                >
                  <Div css={{ display: 'flex', gap: '$md', width: '100%' }}>
                    {parsedApplicationsHubLab
                      .slice(0, firstLineHubLabColumns)
                      .map((application, index) => (
                        <HubLabAppCard
                          data-testid={`HubLabAppCard-isHighlight-${index}`}
                          isHighlight
                          key={application.id}
                          application={application}
                          applicationIdLoading={applicationIdLoading}
                          setApplicationIdLoading={setApplicationIdLoading}
                        />
                      ))}
                  </Div>
                  <Div
                    css={{
                      display: 'flex',
                      gap: '$md',
                      width: '100%',
                      '@sm': {
                        flexWrap: 'wrap'
                      }
                    }}
                  >
                    {parsedApplicationsHubLab
                      .slice(firstLineHubLabColumns)
                      .map((application, index) => (
                        <HubLabAppCard
                          data-testid={`HubLabAppCard-${index}`}
                          key={application.id}
                          application={application}
                          applicationIdLoading={applicationIdLoading}
                          setApplicationIdLoading={setApplicationIdLoading}
                        />
                      ))}
                  </Div>
                </Accordion>
              )}
              {!!parsedApplications.length && (
                <Accordion
                  isOpen={true}
                  Icon={KeyboardArrowRightIcon}
                  Summary={
                    <Typography
                      variant={breakpoint <= Breakpoint.sm ? 'titleMedium' : 'titleLarge'}
                      css={{ color: '$on-surface' }}
                    >
                      {i18n().modules.hub.applications.pages.list.applications}
                    </Typography>
                  }
                  css={{
                    borderStyle: 'none',
                    '& .Accordion-Summary': {
                      gap: '$2xs',
                      padding: '$md $none'
                    },
                    '& .Accordion-Details': {
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '$md',
                      flexWrap: 'wrap'
                    }
                  }}
                >
                  {parsedApplications.map((application, index) => (
                    <AppCard
                      data-testid={`AppCard-${index}`}
                      key={application.id}
                      application={application}
                      applicationIdLoading={applicationIdLoading}
                      setApplicationIdLoading={setApplicationIdLoading}
                    />
                  ))}
                </Accordion>
              )}
              {!!parsedComplementaryTools.length && (
                <Accordion
                  isOpen={true}
                  Icon={KeyboardArrowRightIcon}
                  Summary={
                    <Typography
                      variant={breakpoint <= Breakpoint.sm ? 'titleMedium' : 'titleLarge'}
                      css={{ color: '$on-surface' }}
                    >
                      {i18n().modules.hub.applications.pages.list.complementaryApplications}
                    </Typography>
                  }
                  css={{
                    borderStyle: 'none',
                    '& .Accordion-Summary': {
                      gap: '$2xs',
                      padding: '$md $none'
                    },
                    '& .Accordion-Details': {
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '$md',
                      flexWrap: 'wrap'
                    }
                  }}
                >
                  {parsedComplementaryTools.map((application, index) => (
                    <ToolCard
                      data-testid={`ToolCard-${index}`}
                      key={application.id}
                      application={application}
                      applicationIdLoading={applicationIdLoading}
                      setApplicationIdLoading={setApplicationIdLoading}
                    />
                  ))}
                </Accordion>
              )}
            </>
          )}
        </Div>
        {breakpoint === Breakpoint.xs && <Footer />}
      </Div>
    </Main>
  )
}
