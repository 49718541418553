import * as yup from 'yup'

import { formatPtBrDateToIsoString, isValidDate } from '@/common/helpers'
import { i18n } from '@/common/i18n'

export function validateSchoolYearDateRange(initialDate: string, finalDate: string): boolean {
  const currentInitialDate = new Date(formatPtBrDateToIsoString(initialDate))
  const currentFinalDate = new Date(formatPtBrDateToIsoString(finalDate))
  return currentInitialDate < currentFinalDate
}

export const filterFormeSchema = yup.object().shape({
  startDate: yup
    .string()
    .test({
      name: 'validateDate',
      message:
        i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.validators.validateDate(
          'initial'
        ),
      test: (value) => {
        if (value) {
          return isValidDate(value).isValid
        } else {
          return true
        }
      }
    })
    .test({
      name: 'validateRangeDate',
      message:
        i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.validators.validateRangeDate(
          'initial'
        ),
      test: (value, context) => {
        const parentWithType = context.parent as { endDate: string }
        const endDate = parentWithType.endDate
        if (endDate && value) {
          return validateSchoolYearDateRange(value, endDate)
        } else {
          return true
        }
      }
    }),
  endDate: yup
    .string()
    .test({
      name: 'validateDate',
      message:
        i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.validators.validateDate(
          'final'
        ),
      test: (value) => {
        if (value) {
          return isValidDate(value).isValid
        } else {
          return true
        }
      }
    })
    .test({
      name: 'validateRangeDate',
      message:
        i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.validators.validateRangeDate(
          'final'
        ),
      test: (value, context) => {
        const parentWithType = context.parent as { startDate: string }
        const startDate = parentWithType.startDate
        if (startDate && value) {
          return validateSchoolYearDateRange(startDate, value)
        } else {
          return true
        }
      }
    })
})
