export function Mascot(): JSX.Element {
  return (
    <svg
      width="240"
      height="212"
      viewBox="0 0 240 212"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_30925_33913)">
        <path
          d="M131.447 201.598C131.447 207.034 111.086 211.441 85.97 211.441C60.8538 211.441 40.4932 207.034 40.4932 201.598C40.4932 196.161 60.8538 191.754 85.97 191.754C111.086 191.754 131.447 196.161 131.447 201.598Z"
          fill="#A2ACBE"
          fillOpacity="0.5"
        />
        <path
          d="M136.49 197.204C136.49 195.991 137.474 195.008 138.687 195.008H149.751C150.964 195.008 151.948 195.991 151.948 197.204C151.948 198.417 150.964 199.401 149.751 199.401H138.687C137.474 199.401 136.49 198.417 136.49 197.204Z"
          fill="#A2ACBE"
          fillOpacity="0.5"
        />
        <path
          d="M20.9678 197.204C20.9678 195.991 21.9512 195.008 23.1643 195.008H34.2285C35.4416 195.008 36.425 195.991 36.425 197.204C36.425 198.417 35.4416 199.401 34.2285 199.401H23.1643C21.9512 199.401 20.9678 198.417 20.9678 197.204Z"
          fill="#A2ACBE"
          fillOpacity="0.5"
        />
        <path
          d="M140.321 139.976L138.798 150.221C136.794 163.708 127.171 174.82 114.105 178.73L111.323 179.563C95.1531 184.403 77.9199 184.403 61.7496 179.563L58.9677 178.73C45.9019 174.82 36.2789 163.708 34.2747 150.221L32.7523 139.976C29.7339 119.632 29.4356 98.9766 31.8662 78.5555L34.9531 52.6382C36.5484 39.2444 45.6699 27.9426 58.4285 23.5553C76.6449 17.2924 96.4324 17.2924 114.649 23.5553C127.407 27.9426 136.529 39.2422 138.124 52.636L141.211 78.5533C143.642 98.9766 143.344 119.63 140.323 139.973L140.321 139.976Z"
          fill="#DEE4ED"
        />
        <path
          d="M47.3119 57.2427L47.7051 54.5018C49.0646 45.0294 56.4486 37.5501 65.9097 36.0631L71.1679 35.2375C79.8559 33.8726 88.6972 33.7949 97.4052 35.0089L106.675 36.3006C116.301 37.6411 123.878 45.1826 125.255 54.7947L125.606 57.2427C126.735 65.1082 126.664 73.1002 125.395 80.9457C123.918 90.0873 116.821 97.2959 107.697 98.9249L100.997 100.121C89.9434 102.094 78.6185 101.97 67.6113 99.7528L64.8057 99.189C55.8933 97.3958 49.029 90.2671 47.5784 81.2986L47.5207 80.9457C46.2523 73.1002 46.1812 65.1104 47.3097 57.2427H47.3119Z"
          fill="#5D6675"
        />
        <path
          d="M92.8298 76.391C92.8298 80.0673 89.9629 80.0476 86.4263 80.0476C82.8897 80.0476 80.0227 80.0673 80.0227 76.391C80.0227 72.7147 82.8897 72.559 86.4263 72.559C89.9629 72.559 92.8298 72.7147 92.8298 76.391Z"
          fill="white"
        />
        <path
          d="M73.0337 63.4003C73.0337 65.6018 71.2489 67.3866 69.0473 67.3866C66.8457 67.3866 65.061 65.6018 65.061 63.4003C65.061 61.1987 66.8457 59.4139 69.0473 59.4139C71.2489 59.4139 73.0337 61.1987 73.0337 63.4003Z"
          fill="white"
        />
        <path
          d="M106.552 63.4003C106.552 65.6018 104.767 67.3866 102.565 67.3866C100.364 67.3866 98.5788 65.6018 98.5788 63.4003C98.5788 61.1987 100.364 59.4139 102.565 59.4139C104.767 59.4139 106.552 61.1987 106.552 63.4003Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.09 71.5198C61.503 71.9328 61.503 72.6024 61.09 73.0155L57.6731 76.4323C57.2601 76.8453 56.5905 76.8453 56.1775 76.4323C55.7644 76.0193 55.7644 75.3497 56.1775 74.9367L59.5943 71.5198C60.0073 71.1068 60.677 71.1068 61.09 71.5198Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M102.744 71.5198C103.157 71.9328 103.157 72.6024 102.744 73.0155L99.3275 76.4323C98.9144 76.8453 98.2448 76.8453 97.8318 76.4323C97.4188 76.0193 97.4188 75.3497 97.8318 74.9367L101.249 71.5198C101.662 71.1068 102.331 71.1068 102.744 71.5198Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.8093 71.5198C66.2223 71.9328 66.2223 72.6024 65.8093 73.0155L62.3924 76.4323C61.9794 76.8453 61.3098 76.8453 60.8967 76.4323C60.4837 76.0193 60.4837 75.3497 60.8967 74.9367L64.3136 71.5198C64.7266 71.1068 65.3963 71.1068 65.8093 71.5198Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.462 71.5198C107.875 71.9328 107.875 72.6024 107.462 73.0155L104.045 76.4323C103.632 76.8453 102.963 76.8453 102.55 76.4323C102.137 76.0193 102.137 75.3497 102.55 74.9367L105.967 71.5198C106.38 71.1068 107.049 71.1068 107.462 71.5198Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.5041 71.5198C71.9171 71.9328 71.9171 72.6024 71.5041 73.0155L68.0872 76.4323C67.6742 76.8453 67.0046 76.8453 66.5916 76.4323C66.1785 76.0193 66.1785 75.3497 66.5916 74.9367L70.0084 71.5198C70.4214 71.1068 71.0911 71.1068 71.5041 71.5198Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.157 71.5198C113.57 71.9328 113.57 72.6024 113.157 73.0155L109.74 76.4323C109.327 76.8453 108.658 76.8453 108.244 76.4323C107.831 76.0193 107.831 75.3497 108.244 74.9367L111.661 71.5198C112.074 71.1068 112.744 71.1068 113.157 71.5198Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M63.5466 118.842C63.5466 122.903 60.2546 126.195 56.1937 126.195C52.1329 126.195 48.8409 122.903 48.8409 118.842C48.8409 114.781 52.1329 111.489 56.1937 111.489C60.2546 111.489 63.5466 114.781 63.5466 118.842Z"
          fill="#B971E0"
        />
        <path
          d="M60.8483 116.886C60.8483 118.003 59.9422 118.909 58.8246 118.909C57.7069 118.909 56.8009 118.003 56.8009 116.886C56.8009 115.768 57.7069 114.862 58.8246 114.862C59.9422 114.862 60.8483 115.768 60.8483 116.886Z"
          fill="white"
        />
        <path
          d="M100.369 115.262C100.369 114.588 100.915 114.042 101.589 114.042H119.65C120.324 114.042 120.87 114.588 120.87 115.262C120.87 115.936 120.324 116.483 119.65 116.483H101.589C100.915 116.483 100.369 115.936 100.369 115.262Z"
          fill="#CAD3E0"
        />
        <path
          d="M100.369 120.144C100.369 119.47 100.915 118.923 101.589 118.923H119.65C120.324 118.923 120.87 119.47 120.87 120.144C120.87 120.818 120.324 121.364 119.65 121.364H101.589C100.915 121.364 100.369 120.818 100.369 120.144Z"
          fill="#CAD3E0"
        />
        <path
          d="M100.369 125.025C100.369 124.351 100.915 123.805 101.589 123.805H119.65C120.324 123.805 120.87 124.351 120.87 125.025C120.87 125.699 120.324 126.245 119.65 126.245H101.589C100.915 126.245 100.369 125.699 100.369 125.025Z"
          fill="#CAD3E0"
        />
        <path
          d="M71.2605 175.653C60.3005 176.206 41.2696 160.148 38.0292 138.637C37.8672 137.561 39.3388 137.186 39.8507 138.146C48.0664 153.556 64.5065 166.566 71.7933 171.839C73.4899 173.066 73.352 175.547 71.2605 175.653Z"
          fill="#CAD3E0"
        />
        <path
          d="M82.7098 23.517C96.9564 20.1533 110.941 23.9962 118.677 28.4002C121.012 29.7295 119.703 31.8674 117.073 31.3181C96.802 27.0852 65.3746 27.6099 82.7098 23.517Z"
          fill="#FAFAFB"
        />
        <path
          d="M126.891 33.746C126.891 34.8692 125.98 35.7798 124.857 35.7798C123.733 35.7798 122.823 34.8692 122.823 33.746C122.823 32.6227 123.733 31.7121 124.857 31.7121C125.98 31.7121 126.891 32.6227 126.891 33.746Z"
          fill="#FAFAFB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.50042 143.987C4.59742 148.955 4.5662 153.932 6.32616 157.451L11.4173 154.906C10.5091 153.09 10.2555 149.654 11.1007 145.005C11.9196 140.5 13.68 135.355 16.1726 130.501C18.6691 125.639 21.8305 121.205 25.3694 118.023C27.6853 115.941 32.4935 113.696 32.4935 113.696C32.4935 113.696 32.4089 111.343 31.465 108.061C27.8187 109.079 24.4864 111.163 21.564 113.79C17.3741 117.557 13.8264 122.609 11.1091 127.901C8.38785 133.201 6.4296 138.876 5.50042 143.987Z"
          fill="#B5C0D1"
        />
        <path
          d="M29.0654 103.837C29.0654 102.468 30.1749 101.359 31.5436 101.359C32.9123 101.359 34.0218 102.468 34.0218 103.837V119.326C34.0218 120.694 32.9123 121.804 31.5436 121.804C30.1749 121.804 29.0654 120.694 29.0654 119.326V103.837Z"
          fill="#CAD3E0"
        />
        <path
          d="M16.6347 155.037C17.3526 156.866 16.452 158.93 14.6231 159.648L6.85773 162.696C5.02882 163.414 2.96422 162.514 2.2463 160.685L0.922238 157.312C0.204324 155.483 1.10496 153.418 2.93387 152.7L10.6992 149.652C12.5281 148.934 14.5927 149.835 15.3107 151.664L16.6347 155.037Z"
          fill="#9EA3AC"
        />
        <path
          d="M15.0794 156.204C15.3792 156.919 15.0419 157.743 14.326 158.043C13.6101 158.342 12.7867 158.005 12.4869 157.289L10.9778 153.686C10.678 152.97 11.0153 152.146 11.7312 151.847C12.4471 151.547 13.2705 151.884 13.5703 152.6L15.0794 156.204Z"
          fill="#878C96"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3267 164.487C18.2874 164.242 18.2027 163.999 18.0695 163.772L16.6383 161.332C16.0635 160.352 14.8029 160.023 13.8227 160.598C12.8426 161.173 12.5139 162.433 13.0888 163.413L14.2516 165.396C14.2907 165.623 14.3686 165.846 14.4881 166.058L17.0231 170.547C17.5819 171.536 18.8369 171.885 19.8264 171.327C20.8158 170.768 21.1649 169.513 20.6062 168.523L18.3267 164.487ZM18.3267 164.487L18.0711 164.034C17.5124 163.045 16.2573 162.696 15.2679 163.255C14.49 163.694 14.1078 164.564 14.2516 165.396L14.5199 165.854C15.0947 166.834 16.3553 167.163 17.3355 166.588C18.0885 166.146 18.4569 165.3 18.3267 164.487Z"
          fill="#B6BCC7"
        />
        <path
          d="M14.5199 165.854C15.0947 166.834 16.3553 167.163 17.3355 166.588C18.0885 166.146 18.4569 165.3 18.3267 164.487L18.0711 164.034C17.5124 163.045 16.2573 162.696 15.2679 163.255C14.49 163.694 14.1078 164.564 14.2516 165.396L14.5199 165.854Z"
          fill="#B6BCC7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.40519 166.623C8.49058 166.773 8.55832 166.936 8.60492 167.111L9.59791 170.834C9.89079 171.931 9.23818 173.059 8.14026 173.352C7.04235 173.645 5.91489 172.992 5.62201 171.894L4.75181 168.632C5.1998 169.368 6.08284 169.77 6.96853 169.567C8.07618 169.313 8.76851 168.21 8.51491 167.102L8.40519 166.623ZM7.88352 164.344L8.40519 166.623C7.95397 165.829 7.00988 165.407 6.08667 165.653C4.98876 165.946 4.33615 167.074 4.62902 168.172L4.75181 168.632C4.63954 168.448 4.55459 168.242 4.50377 168.02L3.87238 165.263C3.61878 164.155 4.31111 163.052 5.41876 162.798C6.5264 162.544 7.62992 163.237 7.88352 164.344Z"
          fill="#B6BCC7"
        />
        <path
          d="M6.96853 169.567C8.07618 169.313 8.76851 168.21 8.51491 167.102L8.40519 166.623C7.95397 165.829 7.00988 165.407 6.08667 165.653C4.98876 165.946 4.33615 167.074 4.62902 168.172L4.75181 168.632C5.1998 169.368 6.08284 169.77 6.96853 169.567Z"
          fill="#B6BCC7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0602 169.283C9.82935 169.03 9.65866 168.715 9.57672 168.357L8.94533 165.599C8.69172 164.492 9.38406 163.388 10.4917 163.135C11.5994 162.881 12.7029 163.573 12.9565 164.681L13.4453 166.816C12.9444 166.237 12.1392 165.962 11.3509 166.176C10.2541 166.473 9.6055 167.602 9.90231 168.699L10.0602 169.283ZM10.0602 169.283L11.2489 173.676C11.5457 174.772 12.6755 175.421 13.7724 175.124C14.8692 174.827 15.5178 173.698 15.221 172.601L13.8744 167.624C13.7909 167.316 13.6414 167.043 13.4453 166.816L13.5879 167.439C13.8415 168.546 13.1491 169.65 12.0415 169.903C11.2917 170.075 10.5439 169.813 10.0602 169.283Z"
          fill="#B6BCC7"
        />
        <path
          d="M12.0415 169.903C13.1491 169.65 13.8415 168.546 13.5879 167.439L13.4453 166.816C12.9444 166.237 12.1392 165.962 11.3509 166.176C10.2541 166.473 9.6055 167.602 9.90231 168.699L10.0602 169.283C10.5439 169.813 11.2917 170.075 12.0415 169.903Z"
          fill="#B6BCC7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.939 154.208C23.7752 153.96 23.5547 153.743 23.2828 153.579L20.859 152.12C19.8855 151.534 18.6212 151.848 18.0352 152.822C17.4492 153.796 17.7633 155.06 18.7368 155.646L21.0543 157.041L22.5199 159.39C23.1214 160.354 24.3906 160.648 25.3546 160.046C26.3186 159.444 26.6125 158.175 26.0109 157.211L24.5133 154.811C24.3599 154.565 24.1631 154.363 23.939 154.208ZM23.939 154.208C23.2843 153.756 22.3968 153.707 21.6786 154.155C20.7146 154.756 20.4207 156.026 21.0223 156.99L21.0543 157.041L21.1605 157.105C22.1341 157.691 23.3983 157.377 23.9844 156.403C24.4067 155.702 24.3615 154.849 23.939 154.208Z"
          fill="#B6BCC7"
        />
        <path
          d="M21.1605 157.105C22.1341 157.691 23.3983 157.377 23.9844 156.403C24.4067 155.702 24.3615 154.849 23.939 154.208C23.2843 153.756 22.3968 153.707 21.6786 154.155C20.7146 154.756 20.4207 156.026 21.0223 156.99L21.0543 157.041L21.1605 157.105Z"
          fill="#B6BCC7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.841 101.878C187.708 98.6311 190.91 94.8207 191.802 90.9879L186.259 89.6964C185.798 91.6742 183.8 94.4799 180.181 97.5183C176.675 100.463 172.035 103.299 167.017 105.443C161.991 107.591 156.727 108.985 151.972 109.174C148.86 109.298 143.726 107.956 143.726 107.956C143.726 107.956 142.289 109.821 140.92 112.949C144.376 114.494 148.271 115.018 152.198 114.862C157.828 114.638 163.784 113.015 169.254 110.677C174.732 108.336 179.863 105.218 183.841 101.878Z"
          fill="#B5C0D1"
        />
        <path
          d="M182.327 86.2645C182.942 84.3986 184.953 83.3846 186.819 83.9998L194.742 86.6119C196.608 87.2271 197.622 89.2385 197.007 91.1044L195.872 94.5459C195.257 96.4119 193.246 97.4258 191.38 96.8106L183.457 94.1985C181.591 93.5833 180.577 91.572 181.192 89.706L182.327 86.2645Z"
          fill="#9EA3AC"
        />
        <path
          d="M184.269 86.3595C184.495 85.6171 185.281 85.1988 186.023 85.4251C186.765 85.6515 187.184 86.4368 186.957 87.1792L185.818 90.9162C185.592 91.6586 184.806 92.0769 184.064 91.8506C183.321 91.6242 182.903 90.8389 183.13 90.0965L184.269 86.3595Z"
          fill="#878C96"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M187.058 77.911C186.932 78.1249 186.843 78.3654 186.8 78.6253L186.343 81.4172C186.16 82.5386 186.92 83.5964 188.042 83.7798C189.163 83.9633 190.221 83.2029 190.404 82.0815L190.775 79.813C190.89 79.6138 190.973 79.3919 191.016 79.1528L191.931 74.0794C192.133 72.9611 191.39 71.8911 190.271 71.6894C189.153 71.4877 188.083 72.2307 187.881 73.349L187.058 77.911ZM187.058 77.911L186.966 78.4224C186.765 79.5407 187.508 80.6108 188.626 80.8124C189.505 80.971 190.354 80.5456 190.775 79.813L190.861 79.2896C191.044 78.1682 190.284 77.1104 189.163 76.927C188.301 76.7861 187.477 77.2021 187.058 77.911Z"
          fill="#B6BCC7"
        />
        <path
          d="M190.861 79.2896C191.044 78.1682 190.284 77.1104 189.163 76.927C188.301 76.7861 187.477 77.2021 187.058 77.911L186.966 78.4224C186.765 79.5407 187.508 80.6108 188.626 80.8124C189.505 80.971 190.354 80.5456 190.775 79.813L190.861 79.2896Z"
          fill="#B6BCC7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.392 89.4753L194.446 87.3886C194.286 88.2873 194.743 89.2151 195.611 89.615C196.643 90.0906 197.865 89.6395 198.341 88.6075L198.54 88.1749C198.509 88.3885 198.443 88.6007 198.341 88.804L197.066 91.3295C196.554 92.3439 195.316 92.7511 194.302 92.2391C193.288 91.7271 192.88 90.4897 193.392 89.4753Z"
          fill="#B6BCC7"
        />
        <path
          d="M197.431 86.0401C196.417 85.5281 195.179 85.9353 194.667 86.9497L194.446 87.3886C194.286 88.2873 194.743 89.2151 195.611 89.615C196.643 90.0906 197.865 89.6395 198.341 88.6075L198.54 88.1749C198.665 87.3228 198.242 86.4495 197.431 86.0401Z"
          fill="#B6BCC7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M194.87 84.2847C194.886 84.627 194.817 84.9781 194.651 85.3059L193.377 87.8314C192.865 88.8458 191.627 89.2531 190.613 88.741C189.598 88.229 189.191 86.9916 189.703 85.9772L190.69 84.0219C190.706 84.7871 191.15 85.5128 191.893 85.8519C192.927 86.3238 194.147 85.8684 194.619 84.8347L194.87 84.2847Z"
          fill="#B6BCC7"
        />
        <path
          d="M193.742 82.542C192.727 82.03 191.49 82.4372 190.978 83.4517L190.69 84.0219C190.706 84.7871 191.15 85.5128 191.893 85.8519C192.927 86.3238 194.147 85.8684 194.619 84.8347L194.87 84.2847C194.837 83.5675 194.428 82.8886 193.742 82.542Z"
          fill="#B6BCC7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M176.176 82.2385C176.144 82.5343 176.175 82.8418 176.28 83.1414L177.214 85.8118C177.589 86.8844 178.762 87.4499 179.835 87.0748C180.908 86.6997 181.473 85.5262 181.098 84.4535L180.205 81.9001L180.577 79.1567C180.729 78.0307 179.94 76.9942 178.814 76.8417C177.688 76.6891 176.652 77.4782 176.499 78.6043L176.119 81.4077C176.08 81.6948 176.103 81.9762 176.176 82.2385ZM176.176 82.2385C176.391 83.0049 177.043 83.6091 177.882 83.7227C179.008 83.8753 180.044 83.0861 180.197 81.9601L180.205 81.9001L180.164 81.7831C179.789 80.7105 178.615 80.145 177.543 80.5201C176.77 80.7904 176.26 81.4755 176.176 82.2385Z"
          fill="#B6BCC7"
        />
        <path
          d="M180.164 81.7831C179.789 80.7105 178.615 80.145 177.543 80.5201C176.77 80.7904 176.26 81.4755 176.176 82.2385C176.391 83.0049 177.043 83.6091 177.882 83.7227C179.008 83.8753 180.044 83.0861 180.197 81.9601L180.205 81.9001L180.164 81.7831Z"
          fill="#B6BCC7"
        />
        <path
          d="M144.731 103.837C144.731 102.468 143.621 101.359 142.253 101.359C140.884 101.359 139.775 102.468 139.775 103.837V119.326C139.775 120.694 140.884 121.804 142.253 121.804C143.621 121.804 144.731 120.694 144.731 119.326V103.837Z"
          fill="#CAD3E0"
        />
        <rect x="139.48" y="104.239" width="3.90499" height="16.1081" rx="1.95249" fill="#CAD3E0" />
        <rect
          x="155.518"
          y="0.970856"
          width="83.8027"
          height="65.0422"
          rx="8"
          fill="#BB1614"
          fillOpacity="0.2"
        />
        <path
          d="M167.623 18.8991C167.623 17.2422 168.966 15.8991 170.623 15.8991H172.977C174.633 15.8991 175.977 17.2422 175.977 18.8991V50.8417C175.977 52.4986 174.633 53.8417 172.977 53.8417H170.623C168.966 53.8417 167.623 52.4986 167.623 50.8417V18.8991Z"
          fill="#BB1614"
          fillOpacity="0.2"
        />
        <path
          d="M180.502 38.7406C180.502 37.0838 181.845 35.7406 183.502 35.7406H185.855C187.512 35.7406 188.855 37.0838 188.855 38.7406V50.8417C188.855 52.4986 187.512 53.8417 185.855 53.8417H183.502C181.845 53.8417 180.502 52.4986 180.502 50.8417V38.7406Z"
          fill="#BB1614"
          fillOpacity="0.2"
        />
        <path
          d="M193.381 31.7787C193.381 30.1219 194.724 28.7787 196.381 28.7787H198.734C200.391 28.7787 201.734 30.1219 201.734 31.7787V50.8417C201.734 52.4986 200.391 53.8417 198.734 53.8417H196.381C194.724 53.8417 193.381 52.4986 193.381 50.8417V31.7787Z"
          fill="#BB1614"
          fillOpacity="0.2"
        />
        <path
          d="M206.26 34.2152C206.26 32.5584 207.603 31.2152 209.26 31.2152H211.613C213.27 31.2152 214.613 32.5584 214.613 34.2152V50.8417C214.613 52.4986 213.27 53.8417 211.613 53.8417H209.26C207.603 53.8417 206.26 52.4986 206.26 50.8417V34.2152Z"
          fill="#BB1614"
          fillOpacity="0.2"
        />
        <path
          d="M219.139 27.9496C219.139 26.2928 220.482 24.9496 222.139 24.9496H224.492C226.149 24.9496 227.492 26.2928 227.492 27.9496V50.8417C227.492 52.4986 226.149 53.8417 224.492 53.8417H222.139C220.482 53.8417 219.139 52.4986 219.139 50.8417V27.9496Z"
          fill="#BB1614"
          fillOpacity="0.2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M170.506 19.5293L173.096 18.0153L185.495 39.2264L197.93 30.3911L210.565 33.1988L222.255 26.8527L223.686 29.4892L211.01 36.3708L198.581 33.6089L184.56 43.5711L170.506 19.5293Z"
          fill="#BB1614"
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_30925_33913">
          <rect width="240" height="212" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
