import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { Span } from '@positivote/design-system/components/Span'
import { Typography } from '@positivote/design-system/components/Typography'
import { KeyboardArrowRightIcon } from '@positivote/design-system/icons/KeyboardArrowRight'
import { TodayIcon } from '@positivote/design-system/icons/Today'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { fullFormatDate } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'

import { useListBannerJson } from './hook'

export function Banner(): JSX.Element {
  const { profile } = useAuth()
  const navigate = useNavigate()
  const baseJson = useListBannerJson({
    queryOptions: {
      staleTime: 0
    }
  })

  const [currentPosition, setCurrentPosition] = useState(0)

  function nextImage(): void {
    if (baseJson.data) {
      setCurrentPosition((prev) => (prev + 1) % baseJson.data.length)
    }
  }

  function prevImage(): void {
    if (baseJson.data) {
      setCurrentPosition((prev) => (prev - 1 + baseJson.data.length) % baseJson.data.length)
    }
  }

  const today = new Date()

  return (
    <Div css={{ display: 'flex', gap: '$lg', flexDirection: 'column', marginBottom: '$lg' }}>
      <Div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          background: '$surface-1',
          padding: '$md',
          borderRadius: '$lg'
        }}
      >
        <Typography variant="titleMedium">
          {i18n().common.components.banner.hello}
          <Span css={{ color: '$primary' }}>{profile?.name.split(' ')[0]}</Span>!
          {i18n().common.components.banner.welcome}
        </Typography>

        <Div css={{ display: 'flex', gap: '$sm' }}>
          <TodayIcon />
          <Typography variant="bodyLarge">{fullFormatDate(today)}</Typography>
        </Div>
      </Div>

      <Div
        css={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: '$sm',
          overflow: 'hidden',
          borderRadius: '$lg'
        }}
      >
        <Div
          css={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '100%',
            transition: 'transform 0.5s ease-in-out',
            transform: `translateX(-${currentPosition * 100}%)`,
            borderRadius: '$lg',
            minHeight: 230,
            position: 'relative'
          }}
        >
          {baseJson.data?.map((item, index) => (
            <Div
              key={index}
              css={{
                position: 'relative',
                minWidth: '100%',
                height: '100%',
                backgroundImage: `url("${item.backgroud}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '$lg'
              }}
            >
              <Div
                css={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  borderRadius: '$lg'
                }}
              />

              <Div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  width: '60%',
                  padding: '$none $md',
                  gap: '$sm'
                }}
              >
                <Typography variant="titleLarge" css={{ color: item.text_color }}>
                  {item.title}
                </Typography>
                <Typography variant="bodyLarge" css={{ color: item.text_color }}>
                  {item.description}
                </Typography>
                <Div css={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    id="AccessContent"
                    onClick={() => {
                      item.cta.type === 'OPEN_EMBEDED'
                        ? navigate('/educational-contents', {
                            state: {
                              embedded: item.cta.url
                            }
                          })
                        : window.open(item.cta.url, '_blank')
                    }}
                    variant="tonal"
                  >
                    {i18n().common.components.banner.AccessContent}
                  </Button>
                </Div>
              </Div>
            </Div>
          ))}
        </Div>
        <Div
          css={{
            position: 'absolute',
            top: 100,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '$none $md',
            zIndex: 10
          }}
        >
          <IconButton
            id="left"
            variant="standard"
            onClick={prevImage}
            css={{
              background: '#FFFFFF66',
              backdropFilter: 'blur(10px)',
              borderRadius: '50%',
              padding: '$sm',
              alignItems: 'center'
            }}
          >
            <KeyboardArrowRightIcon fill="$on-surface" css={{ transform: 'rotate(180deg)' }} />
          </IconButton>
          <IconButton
            id="right"
            variant="standard"
            onClick={nextImage}
            css={{
              background: '#FFFFFF66',
              backdropFilter: 'blur(10px)',
              borderRadius: '50%',
              padding: '$sm',
              alignItems: 'center'
            }}
          >
            <KeyboardArrowRightIcon fill="$on-surface" />
          </IconButton>
        </Div>
        <Div
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '$sm',
            borderRadius: '$lg'
          }}
        >
          {baseJson.data?.map((_, index) => (
            <Div
              key={index}
              css={{
                height: '8px',
                width: '8px',
                borderRadius: '$full',
                background: index === currentPosition ? '$primary' : '$on-surface-variant'
              }}
            />
          ))}
        </Div>
      </Div>
    </Div>
  )
}
