import { Navigate, Route, Routes } from 'react-router-dom'

import { ListReports } from './pages/List'

export function ReportRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<ListReports />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
