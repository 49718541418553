import { Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function TableChartIcon({ fill, size = '$lg', css, ...restProps }: IconProps): JSX.Element {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      css={{
        fill: 'none',
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        ...css
      }}
      {...restProps}
    >
      <Path
        d="M9.5 10.02H14.5V21H9.5V10.02ZM16.5 21H19.5C20.6 21 21.5 20.1 21.5 19V10H16.5V21ZM19.5 3H4.5C3.4 3 2.5 3.9 2.5 5V8H21.5V5C21.5 3.9 20.6 3 19.5 3ZM2.5 19C2.5 20.1 3.4 21 4.5 21H7.5V10H2.5V19Z"
        css={{ fill }}
      />
    </Svg>
  )
}
