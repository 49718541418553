export function splitClassLevel(classLevel: string): {
  segment?: string
  level: string
} {
  const [segment, level] = classLevel.split(' - ')
  if (!level) {
    return { level: segment }
  }
  return { segment, level }
}
