import { Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function SchoolOrgIcon({ fill, size = '$lg', css, ...restProps }: IconProps): JSX.Element {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      css={{
        fill: 'none',
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        ...css
      }}
      {...restProps}
    >
      <Path
        d="M7 5.5L12 3.5L17 5.5V9.5H23V19.5H1V9.5H7V5.5ZM9 17.5H11V15.5H9V17.5ZM9 13.5H11V11.5H9V13.5ZM9 9.5H11V7.5H9V9.5ZM13 17.5H15V15.5H13V17.5ZM13 13.5H15V11.5H13V13.5ZM13 9.5H15V7.5H13V9.5ZM17 17.5H21V11.5H17V17.5ZM18 13.5H20V15.5H18V13.5ZM3 17.5H7V11.5H3V17.5ZM6 13.5H4V15.5H6V13.5Z"
        css={{ fill }}
      />
    </Svg>
  )
}
